import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-reporteria',
  templateUrl: './reporteria.component.html',
  styleUrls: ['./reporteria.component.css']
})
export class ReporteriaComponent implements OnInit {
  roleId=localStorage.getItem("roleId");
  index = 0;
//public helper: HelperService,
  constructor(
    
  ) { }

  ngOnInit() {
    this.index = 0;
  }

  // actualizarRanking(event){
  //   console.log(event.index)
  //   //index = 2
  //   if(event.index == 2){
  //     this.helper.cambio = true;
  //   }else{
  //     this.helper.cambio = false;
  //   }
  // }

}

import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }
  public Mensaje;
  NoComienzaconCero(meta:string){
    return (formGroup:FormGroup) =>{
      const metaField = formGroup.controls[meta];
      if (metaField.value > 0) {
        metaField.setErrors(null);
      }else{
        this.Mensaje = 'El valor de la meta debe ser superior a 0';
        metaField.setErrors(this.Mensaje);
      }
    }
  }
  
  primerCaracterTres(referencia:string){
    return ( formGroup:FormGroup) =>{
      const referenciaform = formGroup.controls[referencia];
      let caract = referenciaform.value;
      if (caract!=null && caract !=undefined) {
        let caracter = referenciaform.value.toString().charAt(0);
      if (caracter==="3") {
        this.removerError(referenciaform,'caracterTres');
      }else{
        referenciaform.setErrors({caracterTres:true});
      }
    }
    }
  }

  primerCaracterCero(referencia:string){
    return ( formGroup:FormGroup) =>{
      const referenciaform = formGroup.controls[referencia];
      let caract = referenciaform.value;
      if (caract!=null && caract !=undefined) {
        let caracter = referenciaform.value.toString().charAt(0);
      if (caracter!="0") {
        this.removerError(referenciaform,'caracterCero');
      }else{
        referenciaform.setErrors({caracterCero:true});
      }
    }
    }
  }
  
  referenciasNoIguales(referencia1:string,referencia2:string){
    return ( formGroup:FormGroup) =>{
      const referencia1form = formGroup.controls[referencia1];
      const referencia2form = formGroup.controls[referencia2];

      let valor1=referencia1form.value;
      let valor2=referencia2form.value;

      if (valor1!=valor2) {
        this.removerError(referencia1form,'refIguales');
      }else{
        referencia1form.setErrors({refIguales:true});
      }
    }
  }

  numerosRepetidos(referencia:string){
    return ( formGroup:FormGroup) =>{
      const referenciaform = formGroup.controls[referencia];
      let value = referenciaform.value;

      let numerosNovalido=[
        99999999,88888888,77777777,
        66666666,55555555,44444444,
        33333333,22222222,11111111,
      ];

      const encontrado = numerosNovalido.find(e=> e==value)
      if (encontrado==undefined) {
        this.removerError(referenciaform,'numeroRepetido');
      }else{
        referenciaform.setErrors({numeroRepetido:true});
      }
    }
  }

  numerosConsecutivos(referencia:string){
    return ( formGroup:FormGroup) =>{
      const referenciaform = formGroup.controls[referencia];
      let value = referenciaform.value;

      let numerosNovalido=[
        12345678,87654321
      ];

      const encontrado = numerosNovalido.find(e=> e==value)
      if (encontrado==undefined) {
        this.removerError(referenciaform,'numeroConsecutivo');
      }else{
        referenciaform.setErrors({numeroConsecutivo:true});
      }
    }
  }

  removerError(control:AbstractControl, error:string){
    const err = control.errors;
    if(err){
      delete err[error];
      if (!Object.keys(err).length) { 
        control.setErrors(null); 
      } else {
        control.setErrors(err);
      }
    }
  }
  
}

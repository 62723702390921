import { Pipe, PipeTransform } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DatosClienteDocumento, VisordiunsaComponent} from '../visordiunsa.component';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(documentos: DatosClienteDocumento[] = VisordiunsaComponent["ArchivoCLiente"], buscar:string = '', buscarF:string = ""): any{
    let FiltrarDocumento: any [];
    if(buscar === "ESTADO" || buscarF != ""){
      FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes("ESTADO")));
    }
    if(buscar === "Factura" || buscarF != ""){
        FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes("Factura")));
      }if(buscar === "Pagare" || buscarF != ""){
        FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes("Pagare")));
      }if(buscar === "Plan" || buscarF != ""){
        FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes("Plan de pago")));
      }if(buscar === "Solicitud" || buscarF != ""){
        FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes("Solicitud")));
      }
      if(buscar === "Contrato" || buscarF != ""){
        FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes("Contrato")));
      }
      if(buscar === "Compra"){
        FiltrarDocumento = documentos.filter(p => (p.nombre.includes("Plan de pago") || p.nombre.includes("Pagare") || p.nombre.includes("Factura")));
      }if(buscar === "" || buscarF != ""){
        FiltrarDocumento = documentos.filter(p => (p.nombre !== 'ESTADO DE CUENTA') && p.fechaControl.toString().includes(buscarF) && (p.nombre.includes(buscar)));
        // FiltrarDocumento = documentos.filter(p => (p.nombre != 'ESTADO') && p.fechaControl.toString().includes(buscarF) && (p.nombre.includes(buscar)));
      }
      if(buscarF != "" || buscarF === null){
        FiltrarDocumento = documentos.filter(p => (p.nombre != 'ESTADO') && p.fechaControl.toString().includes(buscarF) && p.nombre.includes(buscar));
        // FiltrarDocumento = documentos.filter(p => p.fechaControl.toString().includes(buscarF) && (p.nombre.includes(buscar) && (p.nombre !== 'ESTADO DE CUENTA')));
      }if(FiltrarDocumento.length == 0){
        FiltrarDocumento = [
          {
            nombre:"No hay documentos asociados a esa busqueda",
            ubicacion: null,
            fechaControl: null,
          }
        ]
      }if(FiltrarDocumento.length > 0){
        FiltrarDocumento.sort((docu1, docu2) => (docu1.fechaControl > docu2.fechaControl) ? -1 : 1);
      }
      return documentos = FiltrarDocumento.sort((docu1, docu2) => (docu1.fechaControl > docu2.fechaControl) ? -1 : 1);

  }

}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AuthComponent } from "./components/auth/auth.component";
import { AccountComponent } from "./components/account/account.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material";
import { RegisterComponent } from "./components/register/register.component";
import { LoginComponent } from "./components/login/login.component";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AuthGuard } from "./guards/auth.guard";
import { FaceGuard } from "./guards/face.guard";
import { NoAuthGuard } from "./guards/no-auth.guard";

import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { AuthService } from "./services/auth.service";
import { InterceptService } from "./services/intercept.service";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxMaskModule, IConfig } from "ngx-mask";

import {
  NuevoCasoComponent,
  FirmaDialog,
  FingerprintComponent,
  popUpInfo,
  ConfirmacionBotonPanico,
} from "./components/nuevo-caso/nuevo-caso.component";

import { CameraComponent } from "./components/camera/camera.component";
import { WebcamModule } from "ngx-webcam";
import {
  VisordiunsaComponent,
  UploadComponentComponent,
} from "./components/visordiunsa/visordiunsa.component";
import { ConsultaAbordajeComponent } from "./components/consulta-abordaje/consulta-abordaje.component";
import {
  DefinicionesComponent,
  popUpTelPlan,
} from "./components/definiciones/definiciones.component";
import { ConfirmdialogComponent } from "./components/confirmdialog/confirmdialog.component";
import { DesbloquearComponent } from "./components/desbloquear/desbloquear.component";
import {
  AumentocupoComponent,
  popUpInfos,
} from "./components/aumentocupo/aumentocupo.component";
import { ResumenasesorComponent } from "./components/resumenasesor/resumenasesor.component";
import { SupervisorseguimientosComponent } from "./components/supervisorseguimientos/supervisorseguimientos.component";
import { RankingsComponent } from "./components/rankings/rankings.component";
import { ReporteriaComponent } from "./components/reporteria/reporteria.component";
import { ReporteJefeventasComponent } from "./components/reporte-jefeventas/reporte-jefeventas.component";
import { QuoteComponent } from "./components/quote/quote.component";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { FaceDetectorModule } from "ml-face-detector";
import { DniDetectorModule } from "dni-detector";
import {
  FeedrequestsComponent,
  popUpReason,
} from "./components/feedrequests/feedrequests.component";
import { SocketIoModule } from "ngx-socket-io";
import {
  ConveniosComponent,
  popUpConvenio,
} from "./components/convenios/convenios.component";
import { ActualizarInformacionComponent } from "./components/actualizar-informacion/actualizar-informacion.component";
import { ActualizacionDialogComponent } from "./components/actualizacion-dialog/actualizacion-dialog.component";
import { CambiarMetaAsesorComponent } from "./components/cambiar-meta-asesor/cambiar-meta-asesor.component";
import { MensajeNoEncuentraComponent } from "./components/visordiunsa/mensaje-noencuentra.component";
import { FiltroPipe } from "./components/visordiunsa/pipes/filtro.pipe";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { CustomMatPaginatorIntl } from "./components/visordiunsa/Paginater";
import { VisorRiesgoComponent } from "./components/visor-riesgo/visor-riesgo.component";
import { VisorRiesgoInfoClienteComponent } from "./components/visor-riesgo-info-cliente/visor-riesgo-info-cliente.component";
import { VisorRiesgoAumentoCupoComponent } from "./components/visor-riesgo-aumento-cupo/visor-riesgo-aumento-cupo.component";
import { VisorRiesgoDocumentosComponent } from "./components/visor-riesgo-documentos/visor-riesgo-documentos.component";
import { OpcionesClienteComponent } from "./components/opciones-cliente/opciones-cliente.component";
import { JefaturaComponent } from "./components/jefatura/jefatura.component";
import { EditarSupervisorComponent } from "./components/editar-supervisor/editar-supervisor.component";
import { VisorPhotosComponent } from "./components/visor-photos/visor-photos.component";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const routes: Routes = [
  { path: "auth", component: AuthComponent, canActivate: [NoAuthGuard] },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  // { path: "facematch", component: FacematchComponent, canActivate: [FaceGuard] },
  // { path: "home2/:id", component: Home2Component, canActivate: [AuthGuard] },
  { path: "account", component: AccountComponent, canActivate: [AuthGuard] },
  { path: "", component: HomeComponent, canActivate: [AuthGuard] },

  { path: "**", redirectTo: "home" },
];

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    RegisterComponent,
    LoginComponent,
    HomeComponent,
    ToolbarComponent,
    AccountComponent,
    // Home2Component,
    // CoursesComponent,
    // GestionprocesoComponent,
    // MallasComponent,
    // IntradiasComponent,
    // EnrolarComponent,
    CameraComponent,
    // FacematchComponent,
    // ListaAsesoresComponent,
    // DialogOverviewExampleDialog,
    // DialogOverviewExampleDialog2,
    FirmaDialog,
    FingerprintComponent,
    NuevoCasoComponent,
    VisordiunsaComponent,
    UploadComponentComponent,
    ConsultaAbordajeComponent,
    DefinicionesComponent,
    ConfirmdialogComponent,
    popUpTelPlan,
    popUpReason,
    DesbloquearComponent,
    AumentocupoComponent,
    popUpInfo,
    popUpInfos,
    ConfirmacionBotonPanico,
    popUpConvenio,
    ResumenasesorComponent,
    SupervisorseguimientosComponent,
    RankingsComponent,
    ReporteriaComponent,
    ReporteJefeventasComponent,
    QuoteComponent,
    FeedrequestsComponent,
    ConveniosComponent,
    FiltroPipe,
    ActualizarInformacionComponent,
    ActualizacionDialogComponent,
    CambiarMetaAsesorComponent,
    // FeedbackComponent,
    // TramitesComponent,
    // RequerimientosComponent,
    // ListaRequerimientosComponent,
    MensajeNoEncuentraComponent,
    VisorRiesgoComponent,
    VisorRiesgoInfoClienteComponent,
    VisorRiesgoAumentoCupoComponent,
    VisorRiesgoDocumentosComponent,
    OpcionesClienteComponent,
    JefaturaComponent,
    EditarSupervisorComponent,
    VisorPhotosComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    // MatCarouselModule.forRoot(),
    // DndModule,
    // NgxChartsModule,
    WebcamModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    FaceDetectorModule,
    DniDetectorModule,
    SocketIoModule,
    MatPaginatorModule,
  ],
  entryComponents: [
    FirmaDialog,
    ConfirmdialogComponent,
    popUpTelPlan,
    popUpReason,
    popUpInfo,
    popUpInfos,
    ConfirmacionBotonPanico,
    popUpConvenio,
    UploadComponentComponent,
    FingerprintComponent,
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    AuthService,
    FaceGuard,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { WebcamImage } from 'ngx-webcam';
import { DataService } from 'src/app/services/data.service';
import { ActualizacionDialogComponent } from '../actualizacion-dialog/actualizacion-dialog.component';

@Component({
  selector: 'app-actualizar-informacion',
  templateUrl: './actualizar-informacion.component.html',
  styleUrls: ['./actualizar-informacion.component.scss']
})
export class ActualizarInformacionComponent implements OnInit {

  
  public webcamImage: string = null;
  dataIntra: FormGroup;
  dataCorreo: FormGroup;
  waitSend = false;
  loadingImages = false;
  correo;
  foto;
  IdCaso = 0;
  pasoObtenerData = true;
  pasoTomarFoto = false;
  pasoVerFotoCorreo = false;
  pasoCambiandoFoto = false;
  pasoActualizarCorreo = false;
  panelfinal = false;
  panelConsultarNuevo = false;
  finalizar = true;
  img;
  dataActualizar: any;
  displayedColumns: string[] = ['Direccion', 'Correo', 'Telefono'];
  dataSource = [{
    Direccion: "",
    Correo: "",
    Telefono: "",
    IdConsulta: ""
  }];
  dataFinal = [];
  editar1 = true;
  editar2 = true;
  editar3 = true;
  dataInfoActualizar: FormGroup;
  editando = false;
  fotoDisabled = false;
  errorMensaje: string;
  habilitarpasofinal = true;
  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {


  }


  ngOnInit(): void {

    //form del documento
    this.dataIntra = this.formBuilder.group({
      Documento: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
    });
    //form actualizar correo
    this.dataCorreo = this.formBuilder.group({
      Correo: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    });

    this.dataInfoActualizar = this.formBuilder.group({
      Telefono: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(13)] ],
      Direccion: ['', [Validators.required]],
      Correo: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    });

    
    this.dataInfoActualizar.controls['Correo'].disable();
    this.dataInfoActualizar.controls['Direccion'].disable();
    this.dataInfoActualizar.controls['Telefono'].disable();
    this.isValid();
    
    

  }

  isValid() {
    this.dataCorreo.get('Correo').valueChanges.subscribe(valor => {
      if (this.dataCorreo.dirty) {
        return this.errorMensaje = 'El formato del correo es incorrecto';
      }
      return this.errorMensaje = "";
    });
    this.dataInfoActualizar.get('Correo').valueChanges.subscribe(valor => {
    });
  
    
      
    
    
    

  }

  /*
  
  FUNCIONES DE EVENTO
  
  */
  cancelar() {
    if (this.pasoCambiandoFoto) {
      this.pasoActualizarCorreo = false;
      this.pasoObtenerData = false;
      this.pasoTomarFoto = false;
      this.panelfinal = false;
      this.pasoVerFotoCorreo = true;
      this.pasoCambiandoFoto = false;
      this.fotoDisabled = false;
    } else {
      this.fotoDisabled = false;
      this.pasoObtenerData = true;
      this.pasoTomarFoto = false;
      this.dataIntra.reset();
    }


  }

  pasoFinal() {
    let data = {
      Documento: this.dataIntra.value.Documento
    }
    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      if (response.IdError == 0) {
        if (response.Correo != null && response.Foto != null) {
          this.pasoVerFotoCorreo = false;
          this.panelfinal = true;
          this.dataActualizar = response;
          this.dataSource[0].Direccion = response.Direccion;
          this.dataSource[0].Correo = response.Correo;
          this.dataSource[0].Telefono = response.Telefono;
          this.dataSource[0].IdConsulta = response.IdConsulta;

        } else {
          this.pasoVerFotoCorreo = true;
          this.openSnackBar('Debe tener correo y foto actualizados', 'cerrar')
        }
      }
    });
  }

  cambiarPantalla() {

    if (this.pasoActualizarCorreo) {
      this.pasoActualizarCorreo = false;
      this.pasoObtenerData = true;
      this.waitSend = false;
      this.pasoTomarFoto = false;
      this.panelfinal = false;
      this.pasoVerFotoCorreo = false;
      this.fotoDisabled =true;
      this.dataIntra.reset();
      this.dataCorreo.reset();
      this.dataInfoActualizar.controls['Correo'].disable();
      this.dataInfoActualizar.controls['Direccion'].disable();
      this.dataInfoActualizar.controls['Telefono'].disable();
      this.dataCorreo.clearValidators();
      this.dataInfoActualizar.clearValidators();
    }
    else if (this.pasoTomarFoto) {
      this.pasoActualizarCorreo = false;
      this.pasoObtenerData = true;
      this.pasoTomarFoto = false;
      this.panelfinal = false;
      this.pasoVerFotoCorreo = false;
      this.dataIntra.reset();
      this.dataCorreo.reset();
      this.waitSend=false;
      
      this.dataInfoActualizar.controls['Correo'].disable();
      this.dataInfoActualizar.controls['Direccion'].disable();
      this.dataInfoActualizar.controls['Telefono'].disable();
      this.dataCorreo.clearValidators();
      this.dataInfoActualizar.clearValidators();
    }
    else if (this.pasoVerFotoCorreo) {
      this.pasoActualizarCorreo = false;
      this.pasoObtenerData = true;
      this.pasoTomarFoto = false;
      this.panelfinal = false;
      this.pasoVerFotoCorreo = false;
      this.dataIntra.reset();
      this.dataCorreo.reset();
      this.dataCorreo.clearValidators();
      this.dataInfoActualizar.clearValidators();
      this.dataInfoActualizar.controls['Correo'].disable();
      this.dataInfoActualizar.controls['Direccion'].disable();
      this.dataInfoActualizar.controls['Telefono'].disable();
      this.waitSend = false;
    }
    else if (this.panelfinal) {
      this.panelfinal = false;
      this.pasoActualizarCorreo = false;
      this.pasoObtenerData = false;
      this.pasoTomarFoto = false;
      this.pasoVerFotoCorreo = true;
      this.dataInfoActualizar.controls['Correo'].disable();
      this.dataInfoActualizar.controls['Direccion'].disable();
      this.dataInfoActualizar.controls['Telefono'].disable();
      this.dataInfoActualizar.clearValidators();
    } else if (this.panelConsultarNuevo) {
      this.panelConsultarNuevo = false;
      this.pasoActualizarCorreo = false;
      this.pasoObtenerData = true;
      this.pasoTomarFoto = false;
      this.panelfinal = false;
      this.pasoVerFotoCorreo = false;
      this.waitSend = false;
      this.dataIntra.reset();
      this.dataCorreo.reset();
      this.finalizar = true;
      this.fotoDisabled = false;
      this.dataInfoActualizar.controls['Correo'].disable();
      this.dataInfoActualizar.controls['Direccion'].disable();
      this.dataInfoActualizar.controls['Telefono'].disable();
      this.dataCorreo.clearValidators();
      this.dataInfoActualizar.clearValidators();
      this.webcamImage = null;

    }


  }

  cancelFlowCamera() {
    this.cancelar();
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage.imageAsDataUrl;
    this.actualizarFoto();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  onKeyCedula(event: any) {
    let num = event.target.value;
    num = num.replace(/[^0-9]/g, '');
    if (num.length > 13) {
      num = num.slice(0, 13);
    }
    this.dataIntra.controls['Documento'].setValue(num);
  }
  onKeyTelefono(event: any) {
    let num = event.target.value;
    num = num.replace(/[^0-9]/g, '');
    if (num.length > 13) {
      num = num.slice(0, 13);
    }
    this.dataInfoActualizar.controls['Telefono'].setValue(num);
  }

  habilitarEdicion() {

    if (this.editar1) {
      this.dataInfoActualizar.controls['Correo'].enable();
      this.dataInfoActualizar.get('Correo').setValidators([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]);
      this.editar1 = false;
    } else if (this.editar1 == false) {
      this.dataInfoActualizar.controls['Correo'].disable();
      this.editar1 = true;
    }
  }
  habilitarEdicion2() {

    if (this.editar2) {
      this.dataInfoActualizar.controls['Direccion'].enable();
      this.dataInfoActualizar.get('Direccion').setValidators([Validators.required]);
      this.editar2 = false;
    } else if (this.editar2 == false) {
      this.dataInfoActualizar.controls['Direccion'].disable();
      this.editar2 = true;
      /**
       * 
      Correo: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    
       */
    }
  }
  habilitarEdicion3() {
    if (this.editar3) {
      this.dataInfoActualizar.controls['Telefono'].enable();
      this.dataInfoActualizar.get('Telefono').setValidators([Validators.required, Validators.minLength(7), Validators.maxLength(13)]);
      this.editar3 = false;
    } else if (this.editar3 == false) {
      
      this.dataInfoActualizar.controls['Telefono'].disable();
      this.editar3 = true;
    }
  }








  /*
 
  FUNCIONES PARA SERVICIOS
  
  */

  //metodo obtener informacion
  consultarFotoCorreo() {
    this.waitSend = true;
    let data = {
      ...this.dataIntra.value
    }
    this.dataService.obtenerCorreoFoto(data).subscribe(data => {
      if (data.IdError == 0) {
        if (data.Correo == null && data.Foto == null && data.Telefono == null && data.Direccion == null) {
          this.waitSend = false;
          this.IdCaso = data.IdConsulta;
          //faltan ambos
          // mostrar habeas data
          this.webcamImage = data.foto;
          this.correo = data.correo
          let aceptaHD = this.habeasDataConfirm(1);
          // Luego va a pedir tomar la foto
          // Luego debe aparecer la pantalla con ver foto y con el correo sin obtener
        } else if (data.Foto == null) {
          this.waitSend = false;
          this.IdCaso = data.IdConsulta;
          this.webcamImage = data.foto;
          this.correo = data.correo
          let aceptaHD = this.habeasDataConfirm(1);
          // mostrar habeas data
          // Luego va a pedir tomar la foto
          // this.pasoObtenerData = false;
          // this.pasoTomarFoto = true;
          this.dataActualizar = data;


          this.dataInfoActualizar.controls["Telefono"].setValue(this.dataActualizar.Telefono);
          this.dataInfoActualizar.controls["Direccion"].setValue(this.dataActualizar.Direccion);
          this.dataInfoActualizar.controls["Correo"].setValue(this.dataActualizar.Correo);

        } 
        else if (data.Correo == null) {

          this.waitSend = false;
          this.IdCaso = data.IdConsulta;
          this.webcamImage = data.foto;
          this.correo = data.correo
          let aceptaHD = this.habeasDataConfirm(2);
          //mostrar pantalla donde dice ver foto yver correo con los iconos
          this.dataActualizar = data;


          this.dataInfoActualizar.controls["Telefono"].setValue(this.dataActualizar.Telefono);
          this.dataInfoActualizar.controls["Direccion"].setValue(this.dataActualizar.Direccion);
          this.dataInfoActualizar.controls["Correo"].setValue(this.dataActualizar.Correo);

        }
        else if(data.Direccion == null)
        {
          this.waitSend = false;
          this.IdCaso = data.IdConsulta;
          this.webcamImage = data.foto;
          this.correo = data.correo
          let aceptaHD = this.habeasDataConfirm(3);
          this.dataActualizar = data;


          this.dataInfoActualizar.controls["Telefono"].setValue(this.dataActualizar.Telefono);
          this.dataInfoActualizar.controls["Direccion"].setValue(this.dataActualizar.Direccion);
          this.dataInfoActualizar.controls["Correo"].setValue(this.dataActualizar.Correo);
        }
        else if(data.Telefono == null )
        {
          this.waitSend = false;
          this.IdCaso = data.IdConsulta;
          this.webcamImage = data.foto;
          this.correo = data.correo
          let aceptaHD = this.habeasDataConfirm(4);
          this.dataActualizar = data;


          this.dataInfoActualizar.controls["Telefono"].setValue(this.dataActualizar.Telefono);
          this.dataInfoActualizar.controls["Direccion"].setValue(this.dataActualizar.Direccion);
          this.dataInfoActualizar.controls["Correo"].setValue(this.dataActualizar.Correo);
        }
        else {
          let aceptaHD = this.habeasDataConfirm(3);
          this.waitSend = false;
          this.webcamImage
          //data completa
          
          this.pasoObtenerData = false;
          this.pasoVerFotoCorreo = true;
          this.dataActualizar = data;


          this.dataInfoActualizar.controls["Telefono"].setValue(this.dataActualizar.Telefono);
          this.dataInfoActualizar.controls["Direccion"].setValue(this.dataActualizar.Direccion);
          this.dataInfoActualizar.controls["Correo"].setValue(this.dataActualizar.Correo);





        }

      } else {
        this.waitSend = false;
        this.openSnackBar(data.Mensaje, 'Cerrar')
      }

    });

  }
  //metodo actualizar la foto
  actualizarFoto() {
    this.loadingImages = true;
    let data = { Documento: this.dataIntra.value.Documento, }

    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      if (response.IdError == 0) {
        this.IdCaso = response.IdConsulta;
        let actualizacion = this.updatePhoto(this.webcamImage, this.IdCaso);
        if (response.Correo == null) {
          this.pasoTomarFoto = false;
          this.pasoActualizarCorreo = true;


        } else {

        }
      } else {
        this.openSnackBar(response.Mensaje, 'Cerrar')
      }
    });

  }
  //metodo para validar si ya existe la foto
  actualizarFotoExistente() {
    this.pasoCambiandoFoto = true;
    this.loadingImages = true;
    let data = {
      Documento: this.dataIntra.value.Documento
    };
    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      
      if (response.IdError == 0) {
        if (response.Foto != null) {
          let cambio = this.fotoConfirm(response.Foto);
          this.waitSend = false;
        }
      } else {
        this.openSnackBar(response.Mensaje, 'Cerrar')
      }
    });
  }
  //metodo para llamar actualizacion
  actualizarCorreo() {
    this.waitSend = true;
    let data = {
      Documento: this.dataIntra.value.Documento
    }
    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      if (response.IdError == 0) {
        this.IdCaso = response.IdConsulta;
        let actualizacion = this.updateMailDB(this.dataCorreo.value.Correo, this.IdCaso);

      } else {
        this.openSnackBar(response.Mensaje, 'Cerrar')

      }
    });

  }
  //metodo para validar si ya existe el correo
  actualizarCorreoExistente() {
    this.waitSend = true;
    let data = {
      Documento: this.dataIntra.value.Documento
    }
    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      if (response.IdError == 0) {
        if (response.Correo != null) {
          let cambio = this.CorreoConfirm(response.Correo);
          this.waitSend = false;

        } else {
          this.waitSend = false;
          this.pasoVerFotoCorreo = false;
          this.pasoActualizarCorreo = true;
        }

      } else {
        this.openSnackBar(response.Mensaje, 'Cerrar')
      }
    });

  }
  //metodo actualizar correo
  updateMailDB(correo, id): number {
    let data = {
      IdConsulta: id,
      Email: correo
    }
    let codigo = 0;
    this.dataService.actualizarCorreo(data).subscribe(response => {
      if (response.IdError == 0) {

        codigo = 0;
        this.pasoVerFotoCorreo = true;
        this.pasoActualizarCorreo = false;
        let data = {
          Documento: this.dataIntra.value.Documento
        }
        this.obtenerInfo(data)

      } else {
        this.openSnackBar(response.Mensaje, 'Cerrar')
        codigo = 1;
      }
    });
    return codigo;
  }
  //Metodo Actualizar foto
  updatePhoto(foto, id): number {
    let data = {
      IdConsulta: id,
      FotoPerfil: foto
    }
    let codigo = 0;
    this.dataService.actualizarFoto(data).subscribe(response => {
      if (response.IdError == 0) {
        
        codigo = 0;
        if (response.Email != null) {
          setTimeout(() => {
            
            this.pasoTomarFoto = false;
            this.pasoVerFotoCorreo = true;
            this.dataActualizar = response;
          }, 3000);
        } else {
          this.pasoTomarFoto = false;
          this.pasoActualizarCorreo = true;
          this.dataActualizar = response;
        }
      } else {
        this.pasoTomarFoto = true;
        this.pasoVerFotoCorreo = false;
        this.pasoActualizarCorreo = false;
        this.dataActualizar = response;
        this.openSnackBar(response.Mensaje, 'Cerrar')
      }
    });


    return codigo;

  }


  ActualizarInformacion() {
    this.fotoDisabled = true;
    this.editando = true;
    this.finalizar = false;
    let data = {
      Documento: this.dataIntra.value.Documento
    }
    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      if (response.IdError == 0) {
        this.dataActualizar = response;
        let updtData = {
          IdConsulta: this.dataActualizar.IdConsulta == null ? "" : this.dataActualizar.IdConsulta,
          Email: this.dataInfoActualizar.value.Correo == '' || this.dataInfoActualizar.value.Correo == null ? this.dataActualizar.Correo : this.dataInfoActualizar.value.Correo,
          Telefono: this.dataInfoActualizar.value.Telefono == '' || this.dataInfoActualizar.value.Telefono == null ? this.dataActualizar.Telefono : this.dataInfoActualizar.value.Telefono,
          Direccion: this.dataInfoActualizar.value.Direccion == '' || this.dataInfoActualizar.value.Direccion == null ? this.dataActualizar.Direccion : this.dataInfoActualizar.value.Direccion,

        }
        
        this.dataService.actualizarInfo(updtData).subscribe(response => {
          
          if (response.IdError == 0) {

            this.panelConsultarNuevo = true;
            this.pasoVerFotoCorreo = false;
            this.dataFinal = response;
            
            

          } else {
            this.openSnackBar(response.Mensaje, 'cerrar');
          }
        });


      }
    })


  }

  obtenerInfo(data){
    let info;
    this.dataService.obtenerCorreoFoto(data).subscribe(response => {
      
      if (response.IdError == 0) {
        this.dataActualizar = response;
        this.dataFinal = response;
            this.dataInfoActualizar.controls["Telefono"].setValue(this.dataActualizar.Telefono);
            this.dataInfoActualizar.controls["Direccion"].setValue(this.dataActualizar.Direccion);
            this.dataInfoActualizar.controls["Correo"].setValue(this.dataActualizar.Correo);
          
        
      } else {
        this.openSnackBar(response.Mensaje, 'cerrar')
      }
    })
    return info;
  }





  /*
 
  VENTANAS EMERGENTES
  
  */

  // emergente para habeas data
  habeasDataConfirm(id): number {
    


    let estado = 0;
    const dialogRef = this.dialog.open(ActualizacionDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: { foto: false, correo: false, habeasdata: true , faltacorreo:true}

    });

    dialogRef.afterClosed().subscribe((confirmar: Boolean) => {
      if (confirmar) {
        if (id == 1) {
          estado = 1;
          this.pasoTomarFoto = true;
          this.pasoObtenerData = false;
        } else if (id == 2) {
          estado = 2;
          this.pasoActualizarCorreo = true;
          this.pasoObtenerData = false;
        }
        else if(id == 3 || id == 4){
          
            estado = 3;
            this.pasoTomarFoto = false;
            this.pasoObtenerData = false;
            this.pasoVerFotoCorreo = true;
          
        } 
        
        else {
          estado = 3;
          this.pasoTomarFoto = false;
          this.pasoObtenerData = false;
          this.pasoVerFotoCorreo = true;
        }
      } else {
        estado = 0;
        this.pasoObtenerData = true;
        this.pasoTomarFoto = false;
        this.pasoActualizarCorreo = false;
        this.pasoVerFotoCorreo = false; 
      }
    });

    return estado;
  }
  //emergente foto
  fotoConfirm(foto) {
    let estado = 1;
    const dialogRef = this.dialog.open(ActualizacionDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: { foto: true, correo: false, dataFoto: foto }

    });
    dialogRef.afterClosed().subscribe((confirmar: Boolean) => {
      if (confirmar) {
        estado = 0
        this.pasoTomarFoto = true;
        this.pasoObtenerData = false;
        this.pasoVerFotoCorreo = false;
      } else {
        estado = 1;
        this.pasoObtenerData = false;
        this.pasoVerFotoCorreo = true;
        this.pasoActualizarCorreo = false;
      }
    })
  }
  //emergente acepta cambio de correo
  CorreoConfirm(correo): number {
    let estado = 1;
    const dialogRef = this.dialog.open(ActualizacionDialogComponent, {
      width: '50%',
      height: '50%',
      data: { foto: false, correo: true, dataCorreo: correo }

    });

    dialogRef.afterClosed().subscribe((confirmar: Boolean) => {
      if (confirmar) {
        estado = 0
        this.pasoActualizarCorreo = true;
        this.pasoObtenerData = false;
        this.pasoVerFotoCorreo = false;
      } else {
        estado = 1;
        this.pasoObtenerData = false;
        this.pasoVerFotoCorreo = true;
        this.pasoActualizarCorreo = false;
      }
    });

    return estado;
  }

  actualizarInfo() {
    let data = this.dataSource
    let cambio = this.dialogEditInfo(data)
    
  }

  dialogEditInfo(info): number {
    let estado = 1;
    const dialogRef = this.dialog.open(ActualizacionDialogComponent, {
      width: '50%',
      height: '50%',
      data: { foto: false, correo: false, info: true, dataInfo: info }

    });

    dialogRef.afterClosed().subscribe((confirmar: Boolean) => {
      if (confirmar) {
        estado = 0
        this.panelConsultarNuevo = true;
        this.panelfinal = false
        this.pasoActualizarCorreo = false;
        this.pasoObtenerData = false;
        this.pasoVerFotoCorreo = false;
        let data = { Documento: this.dataIntra.value.Documento, }

        this.dataService.obtenerCorreoFoto(data).subscribe(response => {
          if (response.IdError == 0) {
            this.dataFinal = response;
          }
        });

      } else {
        estado = 1;
        this.pasoObtenerData = false;
        this.pasoVerFotoCorreo = false;
        this.pasoActualizarCorreo = false;
        this.panelfinal = true
      }
    });

    return estado;
  }




}




import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';
import { ValidatorsService } from 'src/app/services/validators.service';

@Component({
  selector: 'app-cambiar-meta-asesor',
  templateUrl: './cambiar-meta-asesor.component.html',
  styleUrls: ['./cambiar-meta-asesor.component.scss']
})
export class CambiarMetaAsesorComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  metas = [];
  dataSource;
  //heders del paginador
  displayedColumns = ['UserId', 'Nombre', 'Meta', 'FechaCambio', 'Estado'];
  dataIntra:FormGroup;
  formMeta = false;

  //cambio de pestaña
  cambio = true;


  constructor(
    public services: DataService,
    public formBuilder: FormBuilder,
    public validators: ValidatorsService,
    public helper: HelperService,
    private _snackBar: MatSnackBar
  ) { }
  // ngAfterViewInit() {
  //   this.metas.paginator = this.paginator;
  // }

  ngOnInit(): void {
    //servicio en ejecucion de la smetas
    this.obtenerMetas();
    //obtiene del servicio helper(true - false) y luego lo asigna a this.cambio   
    this.helper.cambiarValor.subscribe(x =>{
      this.cambio = x;
    })
    //formulario que envia al servicio el valor de la meta
    this.dataIntra = this.formBuilder.group({
      Meta: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
    },
    {
      //validador para invalidar si es menor o igual a 0
      validators: [this.validators.NoComienzaconCero('Meta')]
    },
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
//Obtiene meta y llena el paginador cada vez que se ejecuta
  obtenerMetas(){
    this.services.listaMetas().subscribe(res =>{
      if(res.IdError == 0){
        this.metas = res.ListaMetas.ListaMetas;
        this.dataSource = new MatTableDataSource(this.metas);
        this.dataSource.paginator = this.paginator;
      }else{
        this.openSnackBar(res.Mensaje,'cerrar')
      }
    });
    
  }

//Evento para restringir el inicio del campo, es decir 1 o mayor
  onKeyNumber(event: any) {
    let num = event.target.value;
    num = num.replace(/[^0-9]/g,'');
    if(num.length > 3){
      num = num.slice(0, 3);
    }
    if(num.charAt(0) === '0'){
      num = num.replace(/[^1-9]/g,'');
      this.dataIntra.controls['Meta'].setValue(num);
    }else{
      this.dataIntra.controls['Meta'].setValue(num);
    }
   

  }

  //evento para registrar la nueva meta
  crearoActualizarMeta(){
    let data = {
      Meta: this.dataIntra.value.Meta
    }
    this.services.cambiarMeta(data).subscribe(res =>{
      if(res.IdError == 0){
        //setea formulario
        this.dataIntra.controls['Meta'].reset();
        //actualiza arreglo llamando el metodo
        this.obtenerMetas();
        //ejecuta el cambio de valor
        this.helper.cambioValorServicio(true);
        this.formMeta = false;
      }else{
        this.openSnackBar(res.Mensaje,'cerrar')
      }
    })
  }

  //evento mostrar formulario de agrgar meta
  crearOActualizarMeta(){
    if(this.formMeta == false){
      this.formMeta = true;
    }else if(this.formMeta == true){
      this.formMeta = false;
    }
  }

  



}


import { Component, OnInit, ViewChild } from "@angular/core";
import { Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { VisorFotos } from "src/app/models/DatosPreguntas";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-visor-photos",
  templateUrl: "./visor-photos.component.html",
  styleUrls: ["./visor-photos.component.css"],
})
export class VisorPhotosComponent implements OnInit {
  //

  form: FormGroup;

  visorFotos: VisorFotos;

  activateStepTwo: boolean = false;

  @ViewChild("stepper") stepper: any;

  message: string;

  activateButton: boolean = true;

  //

  constructor(private fb: FormBuilder, private dataService: DataService) {}

  ngOnInit(): void {
    this.loadDataAndInitiateForm();
  }

  //

  loadDataAndInitiateForm() {
    this.form = this.fb.group({
      document: [null, Validators.required],
    });
  }

  findPhotos() {
    if (this.form.valid) {
      this.activateButton = false;
      console.log("Validate button: ", this.activateButton);
      this.dataService
        .datosVisorFotos(this.form.value.document)
        .subscribe((res) => {
          if (res.IdError == 0 && res.Documento) {
            this.visorFotos = {
              Documento: res.Documento,
              EstadoCliente: res.EstadoCliente,
              FechaControl: res.FechaControl,
              FotoCedula: res.FotoCedula,
              FotoCedulaReverso: res.FotoCedulaReverso,
              FotoPerfil: res.FotoPerfil,
              Id: res.Id,
              IdError: res.IdError,
              Mensaje: res.Mensaje,
              NombreAsesor: res.NombreAsesor,
              PrimerApellido: res.PrimerApellido,
              SegundoApellido: res.SegundoApellido ?? null,
              PrimerNombre: res.PrimerNombre,
              SegundoNombre: res.SegundoNombre ?? null,
              Success: res.Success,
              Tienda: res.Tienda,
              RoleName: res.RoleName,
            };
            this.activateStepTwo = true;
          } else {
            this.message = res.Mensaje;
            this.visorFotos = null;
            this.activateStepTwo = false;
          }
          this.stepper.next();
          setTimeout(() => {
            this.activateButton = true;
          }, 1000);
        });
    }
  }

  onKeyCedula(event: any) {
    let num = event.target.value;
    num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      num = num.slice(0, 13);
    }
    this.form.controls["document"].setValue(num);
  }

  //
}

import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-mensaje',
    templateUrl: './mensaje-noencuentra.component.html',
    styleUrls: ['./mensaje-noencuentra.component.css']
})

export class MensajeNoEncuentraComponent implements OnInit {
    dataInfoActualizar: FormGroup;
    acepta = false;
    constructor(
        public dialogRef: MatDialogRef<MensajeNoEncuentraComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit(): void {

    }


    cancelar() {
        this.dialogRef.close(false);
    }

    confirmar() {
        this.dialogRef.close(true);
    }
}
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-jefatura',
  templateUrl: './jefatura.component.html',
  styleUrls: ['./jefatura.component.scss']
})
export class JefaturaComponent implements OnInit {

  jefaturaEdit = false;
  

  inputPlaceholder = 'Busca por nombre o por usuario';
  supervisores = []
  supervisor:any;

  constructor(
    private dataServices: DataService
  ) { }


  ngOnInit(): void {
    this.cargarSupervisores();
  }
  
  cargarSupervisores(){
    let data ={}
    this.dataServices.consultarSupervisores(data).subscribe(res =>{
      if(res.IdError == 0){
        this.supervisores = res.Supervisores;
        this.cargarInformacion();

      }else{
        //Todo: snack bar para error
      }
    })
  }

  dataDisplay = [];
  //Todo: Variable con ngModel

  currentPage = 1;
  itemsPerPage = 6;
  totalItems: number;
  totalPages: number;

  filtrarNombreUsuario(value){
    let numberValue = value;
    let valueFinal = parseInt(numberValue)
    if(typeof valueFinal === "number" && Number.isInteger(valueFinal)){
      this.dataDisplay = this.supervisores.filter(item => item.UserId.toString().toLowerCase().includes(value.toString()));
    }else{
      this.dataDisplay = this.supervisores.filter(item => item.Name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  valorInputFiltro(event: any) {
    var num = event.target.value;
    //todo:cambiar de pattern
    var num = num.replace(/[^0-9]/g, "");
    //todo valor de longitud
    if (num.length > 1) {
      var num = num.slice(0, 1);
    }
    //todo: poner valor en linea comentada
    // this.aumentarCupo.controls['aniosAntiguedadLaboral'].setValue(num);
  }

//carga los items cortados en un nuevo arreglo
  loadItems(page: number) {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.dataDisplay = this.supervisores.slice(startIndex, endIndex);
  }

  //Llama la funcion de cargar los elemntos para actualizar el arreglo a ver
  actualizar() {
    this.loadItems(this.currentPage);
  }

  //carga la informacion de la tabla
  cargarInformacion() {
    this.totalItems = this.supervisores.length;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.loadItems(this.currentPage);
  }

  //adelanta la pagina de la tabla y actualiza arreglo
  btnAnterior() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.actualizar();
    }
  }

  //va hacia atras la pagina de la tabla y actualiza arreglo
  btnSiguiente() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.actualizar();
    }
  }

  editarSupervisor(value){
    this.supervisor = value;
    this.jefaturaEdit = !this.jefaturaEdit;

  }

  volverOpciones(data: any) {
    if (data.cerrar) {
      this.jefaturaEdit = !this.jefaturaEdit;
      this.cargarSupervisores();
    }
  }

}

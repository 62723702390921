import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator, PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { ValidatorsService } from '../../services/validators.service';
import { MatTableDataSource } from '@angular/material/table';
import { CustomPaginator } from './CustomPaginator';

export interface DatosClienteDocumento {
  nombre:string;
  ubicacion:string;
  fechaControl:string;
}

@Component({
  selector: 'app-visordiunsa',
  templateUrl: './visordiunsa.component.html',
  styleUrls: ['./visordiunsa.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})

export class VisordiunsaComponent implements OnInit {

  //Paginador
  @ViewChild(MatPaginator) paginator: MatPaginator;

  static ArchivosCLiente: any;
  static ArchivoCLiente: any;
  
  constructor(
    private _snackBar: MatSnackBar, private formBuilder: FormBuilder, private dataService: DataService,
    public dialog: MatDialog,private validar: ValidatorsService
  ) {
    this.MostrarFiltro = true;
    this.MostrarFiltroFe = true;
    this.dataService.catalogos().subscribe(data => {
      if (data.IdError == 0) {
        for (const e in data.Lista) {
          if (data.Lista[e].TipoCatalogo == 'TipoConstancia') {

            for (const m in data.Lista[e].Catalago) {

              this.constanciaTipo.push({ 'Id': data.Lista[e].Catalago[m].Id, 'Descripcion': data.Lista[e].Catalago[m].Descripcion, 'Show': true });
            }

            // this.constanciaTipo = data.Lista[e].Catalago
          }
          if (data.Lista[e].TipoCatalogo == 'CiudadConstancia') {
            for (const m in data.Lista[e].Catalago) {

              this.ciudad.push({ 'Id': data.Lista[e].Catalago[m].Descripcion, 'Descripcion': data.Lista[e].Catalago[m].Descripcion });
            }
          }

        }
      }
    });
  }
  filtros;
  mresultados=false;
  displayedColumns = ['NombreCompleto', 'NumeroDocumento', 'Telefonob', 'boton'];
  dataSource;
  //ArchivosInterfaz
  ArchivoCLiente: [];
  EstadoCuentaCLiente: [];

  //Paginacion
  buscar: string = '';
  buscarF: string = '';
  fechaBusqueda = new FormControl();
  fechaBusquedaRage = new FormControl();
  fechaMax = new Date();
  MostrarFiltro:boolean;
  MostrarFiltroFe: boolean;
  MostrarFiltroFeRange: boolean;
  pageSize: 10;
  desde:number = 0;
  hasta:number = 10;

  //selector
  mostrarCompras: boolean;
  mostrarEstados: boolean;



  miFormularioPalabraCla: FormGroup = this.formBuilder.group({
    selectPalabraClave: ['', [ ]],
    FechaSeleccionada: ['', [ ]],
  });
  

  dataUsuario = null;
  fechasRow = 4;
  showBtsTable = false;
  waitSend = false;
  docActual;
  waitSendRep = false;
  waitSendCont = false;
  waitSendCsv = false;
  roleId = localStorage.getItem('roleId');
  ciudad = [

  ];
  constanciaTipo = [
  ];
  dataIntra: FormGroup = this.formBuilder.group({
    Filtro: ['', [Validators.required]],
    Documento: ['', [ Validators.minLength(13), Validators.maxLength(13)]],
    Correo: ['',],
    Telefono: ['',],
    PrimerNombre: ['',],
    SegundoNombre: ['',],
    PrimerApellido: ['',],
    SegundoApellido: ['',],
  });
  dataSocioDe: FormGroup = this.formBuilder.group({
    tipoConstancia: ['', [Validators.required, ]],
    institucion: ['', [ ]],
    ciudad: ['', [Validators.required, ]],
  });  
  ngOnInit() {
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }


  download(filename, text) {
    if (text == null) {
    } else {
      window.open(text, '_blank');
    }
  }
  verPdfRepo() {

  }
  onKeyCedula(event: any) {
    let num = event.target.value;
    num = num.replace(/[^0-9]/g, '');
    if (num.length > 13) {
      num = num.slice(0, 13);
    }
    this.dataIntra.controls['Documento'].setValue(num);
  }
  onKeyTelefono(event: any) {
    let num = event.target.value;
    num = num.replace(/[^0-9]/g, '');
    if (num.length > 13) {
      num = num.slice(0, 13);
    }
    this.dataIntra.controls['Telefono'].setValue(num);
  }
  allowCharsfilter(evt) {
      const ev = evt || window.event;
      let key;
    
    
      // Handle paste
      if (ev.type === 'paste') {
        key = evt.clipboardData.getData('text/plain');
      } else {
        // Handle key press
        key = ev.keyCode || ev.which;
        key = String.fromCharCode(key);
      }
      const regex = /^[a-zA-Z \u00f1\u00d1]+$/;
      if (!regex.test(key) ) {
        ev.returnValue = false;
        if (ev.preventDefault) {
          ev.preventDefault();
        }
      }
}
  deleteTrim(event){
    let data = event.target.value;
    event.target.value = data.trimStart();
  }
  filtrar(){
   this.filtros=this.dataIntra.value.Filtro;
   if(this.filtros=='Documento'){
    this.dataIntra.get('Documento').setValidators([Validators.required,]);
    this.dataIntra.get('Correo').setValidators([]);
    this.dataIntra.get('Telefono').setValidators([]);
    this.dataIntra.get('PrimerNombre').setValidators([]);
    this.dataIntra.get('PrimerApellido').setValidators([]);
    this.dataIntra.controls['Correo'].reset();
    this.dataIntra.controls['Telefono'].reset();
    this.dataIntra.controls['PrimerNombre'].reset();
    this.dataIntra.controls['SegundoNombre'].reset();
    this.dataIntra.controls['PrimerApellido'].reset();
    this.dataIntra.controls['SegundoApellido'].reset();
   }else if(this.filtros=='Correo'){
    this.dataIntra.get('Correo').setValidators([Validators.required,]);
    this.dataIntra.get('Documento').setValidators([]);
    this.dataIntra.get('Telefono').setValidators([]);
    this.dataIntra.get('PrimerNombre').setValidators([]);
    this.dataIntra.get('PrimerApellido').setValidators([]);
    this.dataIntra.controls['Documento'].reset();
    this.dataIntra.controls['Telefono'].reset();
    this.dataIntra.controls['PrimerNombre'].reset();
    this.dataIntra.controls['SegundoNombre'].reset();
    this.dataIntra.controls['PrimerApellido'].reset();
    this.dataIntra.controls['SegundoApellido'].reset();
   }else if(this.filtros=='Telefono'){
    this.dataIntra.get('Telefono').setValidators([Validators.required,]);
    this.dataIntra.get('Correo').setValidators([]);
    this.dataIntra.get('Documento').setValidators([]);
    this.dataIntra.get('PrimerNombre').setValidators([]);
    this.dataIntra.get('PrimerApellido').setValidators([]);
    this.dataIntra.controls['Correo'].reset();
    this.dataIntra.controls['Documento'].reset();
    this.dataIntra.controls['PrimerNombre'].reset();
    this.dataIntra.controls['SegundoNombre'].reset();
    this.dataIntra.controls['PrimerApellido'].reset();
    this.dataIntra.controls['SegundoApellido'].reset();
   }else if(this.filtros=='Nombres'){
    this.dataIntra.get('Documento').setValidators([]);
    this.dataIntra.get('Correo').setValidators([]);
    this.dataIntra.get('Telefono').setValidators([]);
    this.dataIntra.get('PrimerNombre').setValidators([Validators.required,]);
    this.dataIntra.get('PrimerApellido').setValidators([Validators.required,]);
    this.dataIntra.controls['Correo'].reset();
    this.dataIntra.controls['Telefono'].reset();
    this.dataIntra.controls['Documento'].reset();
   }
   this.mresultados=false;
   }
  descargarReportePdf() {

    this.waitSendRep = true;
    this.dataService.verPdfRepo(this.docActual).subscribe(response => {

      this.waitSendRep = false;
      if (response.length > 0) {

        const element = document.createElement('a');
        element.setAttribute('href', ' data:application/pdf;base64,' + response);
        element.setAttribute('download', 'Reporte' + '_' + this.docActual + '.pdf');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {

        this.openSnackBar('Se ha generado un error.', 'Cerrar');
      }
    });
  }
  descargarConsta() {
    if (this.dataSocioDe.valid) {
      this.waitSendCont = true;
      const dts = this.docActual + '/' + this.dataSocioDe.value.tipoConstancia.toString() + '/' + this.dataSocioDe.value.institucion.toString() + '/' + this.dataSocioDe.value.ciudad.toString();
      this.dataService.verPdfConstancia(dts).subscribe(response => {
        this.waitSendCont = false;
        if (response.length > 0) {
          const element = document.createElement('a');
          element.setAttribute('href', ' data:application/pdf;base64,' + response);
          element.setAttribute('download', 'Constancia' + '_' + this.docActual + '.pdf');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {
          this.openSnackBar('Sin datos de usuario.', 'Cerrar');
        }
      });
    } else {
      this.openSnackBar('Recuerde llenar todo los campos para su solicitud.', 'Cerrar');
    }

  }
  // enviarBuscar() {
  //   this.MostrarFiltro = true;
  //   this.MostrarFiltroFe = true;
  //   this.dataUsuario = null;
  //   this.docActual = this.docActual || this.dataIntra.value.Documento.toString();
  //   this.waitSend = true;
  //   const data = {
  //     'Identificacion':this.dataIntra.value.Documento.toString(),
  //   };
  //   this.dataService.buscarCliente(data).subscribe(response => {
  //     this.waitSend = false;
  //     this.showBtsTable = true;
  //     if (response.IdError == 0) {
  //       this.dataIntra.reset();
  //       this.dataUsuario = response.Cliente[0];
  //       this.ArchivoCLiente = response.Cliente[0].documentos;
  //       this.EstadoCuentaCLiente = response.Cliente[0].documentos;
  //       // this.ArchivoCLiente.sort();
  //       // const arregloPorFechas = this.ArchivosCLiente.filter(f => f.fechaControl);
       
  //       // console.log(arregloPorFechas);
  //       const sal = parseInt(this.dataUsuario.ValorDeuda);
  //       if (sal < 0) {
  //         for (const e in this.constanciaTipo) {
  //           if (this.constanciaTipo[e].Id == 2) {
  //             this.constanciaTipo[e].Show = false;
  //           }
  //         }
  //       }
  //     } else {
  //       this.showBtsTable = false;
  //       this.dataUsuario = null;
  //       this.openSnackBar(response.Mensaje, 'Cerrar');
  //     }
  //   });
  // }
  buscarNombre(){
    this.dataUsuario = null;
    this.waitSend = true;
    this.mresultados=true;
    let data={
    }

    if(this.filtros=='Nombres'){
      data={
        'filtro': 2,
        'PRIMER_NOMBRE': this.dataIntra.value.PrimerNombre,
        'SEGUNDO_NOMBRE': this.dataIntra.value.SegundoNombre,
        'PRIMER_APELLIDO': this.dataIntra.value.PrimerApellido,
        'SEGUNDO_APELLIDO': this.dataIntra.value.SegundoApellido,
      }
    }
    else if(this.filtros=='Correo'){
      data = {
        'filtro': 3,
        'Correo': this.dataIntra.value.Correo,
      };
    }
    
    else if(this.filtros=='Telefono'){
      data = {
        'filtro': 4,
        'TELEFONO_1': this.dataIntra.value.Telefono,
      };
    }
    
    this.dataService.buscarParamsCliente(data).subscribe(response => {
      this.waitSend = false;
      this.showBtsTable = false;
      if (response.IdError == 0) {
        this.dataUsuario = null; 
        if(this.filtros=='Correo'){
          this.dataUsuario = response.LstCliente1;
          this.dataSource = new MatTableDataSource(this.dataUsuario);
          this.dataSource.paginator = this.paginator;
          this.dataUsuario=null;
        }else{
          this.dataUsuario = response.LstCliente;
          this.dataSource = new MatTableDataSource(this.dataUsuario);
          this.dataSource.paginator = this.paginator;
          this.dataUsuario=null;
        }
      } else {
        this.showBtsTable = false;
        this.dataUsuario = null;
        this.dataIntra.reset();
        this.filtrar();
        this.openSnackBar(response.Mensaje, 'Cerrar');
      }
    });
  }
  enviarBuscar(id?) {
    this.dataUsuario = null;
    this.docActual = this.docActual;// ||id || this.dataIntra.value.Documento;
    this.waitSend = true;
    this.mresultados=false;
    if(this.filtros=='Documento'){
      this.docActual = this.dataIntra.value.Documento;
    }else{
      this.docActual = id;
    }
    let data={
      'IDENTIFICACION': this.docActual,
    }
    this.dataService.buscarCliente(data).subscribe(response => {
      this.waitSend = false;
      this.showBtsTable = true;
      if (response.IdError == 0) {
        this.dataIntra.reset();
        this.dataUsuario = response.Cliente[0];
        this.ArchivoCLiente = response.Cliente[0].documentos;
        this.EstadoCuentaCLiente = response.Cliente[0].documentos;
        const sal = parseInt(this.dataUsuario.ValorDeuda);
        if (sal < 0) {
          for (const e in this.constanciaTipo) {
            if (this.constanciaTipo[e].Id == 2) {
              this.constanciaTipo[e].Show = false;
            }
          }
        }
        this.filtrar();
      } else {
        this.showBtsTable = false;
        this.dataUsuario = null;
        this.openSnackBar(response.Mensaje, 'Cerrar');
      }
    });
  }
  cambiarpagina(e:PageEvent){
    this.desde = e.pageIndex * e.pageSize;
    this.hasta = this.desde + e.pageSize;
  };

  BuscarDocumento(){
    this.paginator.firstPage();
    this.MostrarFiltro = true;
    this.BuscarDocumentoFecha("");
    let selectValu = this.miFormularioPalabraCla.value.selectPalabraClave;
    if(selectValu === "Compra"){
      this.MostrarFiltroFe = false;
    }else{
      this.MostrarFiltroFe = true;
    }
    this.buscar = selectValu;
  }
  BuscarDocumentoFecha(buscarF: string){
    // this.fechaBusqueda.setValue('');
    this.paginator.firstPage();
    this.buscarF = buscarF;
  }
  BuscarDocumentoFecha2(buscarF: string){
    // this.fechaBusquedaRage.setValue('');
    this.paginator.firstPage();
    this.buscarF = buscarF;
  }
  BuscarEstadoCuenta(){
    this.paginator.firstPage();
    this.mostrarCompras = false;
    this.mostrarEstados = true;
    this.BuscarDocumentoFecha("");
    this.fechaBusqueda.setValue('');
    this.fechaBusquedaRage.setValue('');
    this.MostrarFiltroFe = true;
    this.MostrarFiltro = false;
    this.buscar = "ESTADO";
  }
  BuscarCompras(){
    this.paginator.firstPage();
    this.mostrarCompras = true;
    this.mostrarEstados = false;
    this.BuscarDocumentoFecha("");
    this.fechaBusqueda.setValue('');
    this.fechaBusquedaRage.setValue('');
    this.MostrarFiltroFe = true;
    this.MostrarFiltro = true;
    this.buscar = "";
  }

  descargarReporte() {
    this.waitSendCsv = true;
    // var dts = parseInt(this.valueFecha);
    this.dataService.verReporteGeneral().subscribe(data => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const fileOfBlob = new File([blob], 'LogConstancias.csv');
      const objectUrl = URL.createObjectURL(fileOfBlob);
      const fileName = 'LogConstancias.csv';
      this.saveAs(blob, fileName);
      this.waitSendCsv = false;
    });
  }
  saveAs(blob, fileName) {
    const url = window.URL.createObjectURL(blob);

    const anchorElem = document.createElement('a');
    // anchorElem.style = "display: none";
    anchorElem.href = url;
    anchorElem.download = fileName;

    document.body.appendChild(anchorElem);
    anchorElem.click();

    document.body.removeChild(anchorElem);

    // On Edge, revokeObjectURL should be called only after
    // a.click() has completed, atleast on EdgeHTML 15.15048
    setTimeout(function () {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  openUploaderModal() {
    const dialogRef = this.dialog.open(UploadComponentComponent, {
      width: '50%',
      minHeight: '30%',
      height : 'auto',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const formData = {
          ...result,
          NumeroIdentificacion: this.docActual,
          Pdf: result.Pdf.replace('data:application/pdf;base64,', ''),
        };

        this.dataService.uploadFile(formData).subscribe(data => {
          if (data.IdError == 0) {
            this.enviarBuscar();
            this.openSnackBar('El archivo se subió correctamente', 'Cerrar');
          } else {
            this.openSnackBar(data.Mensaje, 'Cerrar');
          }
        });
      }
    });
  }
}

@Component({
  selector: 'app-upload-file',
  templateUrl: 'upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadComponentComponent {

  showConfirm = false;
  selectedOption = '';
  errorSize: boolean;
  file: File;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<UploadComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
    private _snackBar: MatSnackBar
  ) {

  }

  responseCancel() {
    this.dialogRef.close();
  }

  onFileChanged(target: HTMLInputElement): void {
    if (target.files && target.files[0]) {
      this.errorSize = target.files[0].size > 1000000;

      if (!this.errorSize) {
        this.file = target.files[0];
      }
    }
  }

  removeFile() {
    this.file = null;
    this.fileInput.nativeElement.value = '';
  }

  async uploadFile() {
    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    const Pdf = await toBase64(this.file);

    this.dialogRef.close({
      TipoArchivo: this.selectedOption,
      Pdf,
    });
  }
}

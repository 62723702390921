export const environment = {
  production: false,
  // baseUrl : 'https://desarrollo.epik.com.co:6688', // dev
  baseUrl: "https://diunsa.epik.com.co:6645/", // prod
  //baseUrl : 'http://localhost:52103/',

  baseNode: "https://diunsa.epik.com.co:6690", //prod
  // baseNode: 'https://desarrollo.epik.com.co:6601', // desa
  //baseNode: 'http://localhost:8081',
  clientid: "641de0d28d9b37001b1bdfa0",
  // flowid: '644046791b27e9001ce29a33',//desarrollo
  flowid: "643ef506dc533c001babb8f3", //produccion
  // nombreProyecto: "Diunsa Epik (Pruebas)",
  nombreProyecto: "Diunsa Epik",
  tiempoOtp: 3,
  version: "1.1.27",
};

import { OnInit, Inject } from '@angular/core';
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { ListaVendedores } from 'src/app/models/ListaVendedores';
import { Router } from '@angular/router';
import { FormsModule, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DndDropEvent } from 'ngx-drag-drop';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, startWith, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { NuevoCasoComponent } from '../nuevo-caso/nuevo-caso.component';
import { DecimalPipe } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';

declare var io: any;
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements AfterViewInit {
  auth=true;
  public demo1TabIndex = 0;
  roleId = localStorage.getItem("roleId")
  dataIntra: FormGroup;
  widthTab = (window.innerWidth - 10) + "px";
  title = 'angular-gmap';
  waitSend = true;
  greeting = 'Hello Child';
  filterSolicitudes
  solicitudes
  selectArchivar
  showComponent
  showSide
  displayedColumns: string[] = localStorage.getItem("roleId") == "1" ? ['IdConsulta', 'Documento', 'Estado', 'NombreAsesor', 'FechaControl', 'IdDocumento', 'IdAsesor'] : ['IdConsulta', 'Documento', 'Estado', 'NombreAsesor', 'FechaControl', 'IdDocumento'];
  dataSource
  socket;
  baseNode = environment.baseNode;
  version = environment.version
  ngAfterViewInit() {
    
  }
  sockets:any;
  @ViewChild('sidenav') sidenav: MatSidenav;
  requestUpdate: Subject<any> = new Subject();
  screenWidth: number;
  isExpanded = true;
  homepagecards = [
    { 'Estado': true, link: "Inicio", text: "Inicio", image: "home" },
  ]
  objMenu = [
    { 'Nombre': 'Inicio', 'Icono': 'home', 'Estado': false, url: '/home' },

  ];

  newHome = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog, 
    private http: HttpClient, 
    private formBuilder: FormBuilder, 
    private dataService: DataService, 
    private router: Router, 
    private FormsModule: FormsModule,
    private _decimalPipe: DecimalPipe,
    private hp: HelperService
    ) {

    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };

  }
  evalbkg() : object{
    if(this.showComponent==="Inicio"){
      let res={"background": "white url('/assets/img/home.png')  no-repeat ", "background-position": "right bottom" }
      return res
    }else{
      let img= this.homepagecards.find(key => key.link == this.showComponent).image
      let res={"background": "white url('/assets/img/"+img+".png')  no-repeat ", "background-position": "right bottom" }
      return res
    }

   }
  async ngOnInit() {
    this.dataSource = new MatTableDataSource(this.filterSolicitudes);
    
    if(this.roleId=='1'){

      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )

      this.homepagecards.push(
        { 'Estado': false, link: "Abordaje", text: "Abordaje", image: "assignment_turned_in" },
        { 'Estado': false, link: "Consulta abordaje", text: "Consulta abordaje", image: "assignment" },
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
        { 'Estado': false, link: "Cajas", text: "Cajas", image: "face" },
        // { 'Estado': false, link: "Feed", text: "Feed", image: "face" },
        )
    }
    if(this.roleId=='2'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
      //  { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
      )
    }
    /* rol eliminado
    if(this.roleId=='3'){
      this.homepagecards.push(
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
      )
    }*/
    if(this.roleId=='4'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Consulta abordaje", text: "Consulta abordaje", image: "assignment" },
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
      //  { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
        { 'Estado': false, link: "Reporteria", text: "Reporteria", image: "analytics" },
        { 'Estado': false, link: "Aumento de cupo", text: "Aumento de cupo", image: "add_card" },
        { 'Estado': false, link: "Cajas", text: "Cajas", image: "face" },
        )
    }
    if(this.roleId=='5'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Abordaje", text: "Abordaje", image: "assignment_turned_in" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
      // { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
        { 'Estado': false, link:"Actualizar",text:"Actualizar Informacion",image:"assignment_turned_in"} //Probando que aparece
      )
    }
    if(this.roleId=='6'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
        { 'Estado': false, link: "Gestión administrativa", text: "Gestión administrativa", image: "settings" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Abordaje", text: "Abordaje", image: "assignment_turned_in" },
        { 'Estado': false, link: "Consulta abordaje", text: "Consulta abordaje", image: "assignment" },
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
      //  { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
        { 'Estado': false, link: "Reporteria", text: "Reporteria", image: "analytics" },
        { 'Estado': false, link: "Aumento de cupo", text: "Aumento de cupo", image: "add_card" },
        { 'Estado': false, link:"Actualizar",text:"Actualizar Informacion",image:"assignment_turned_in"},
        { 'Estado': false, link: "Desbloquear", text: "Desbloquear", image: "source" },
        { 'Estado': false, link: "Cajas", text: "Cajas", image: "face" },
        { 'Estado': false, link: "Visor de Fotos", text: "Visor de Fotos", image:"image" }

      )
    }
    if(this.roleId=='7'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
      //  { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
      )
    }
    if(this.roleId=='8'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
      )
    }
    if(this.roleId=='9'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
      )
    }
    if(this.roleId=='10'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
      )
    }/* rol eliminado
    if(this.roleId=='11'){
      this.homepagecards.push(
        { 'Estado': false, link: "Abordaje", text: "Abordaje", image: "assignment_turned_in" },
        { 'Estado': false, link: "Consulta abordaje", text: "Consulta abordaje", image: "assignment" },
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
        { 'Estado': false, link: "Cajas", text: "Cajas", image: "face" },
      )
    }*/
  if(this.roleId=='12'){
    this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
    this.homepagecards.push(
        { 'Estado': false, link: "Convenios", text: "Convenios", image: "handshake" }
      );
    }
  if(this.roleId=='17'){
    this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
    this.homepagecards.push(
      { 'Estado': false, link: "Visor riesgo", text: "Visor riesgo", image: "face" },

      );
    }
    //metaventa(administrador)
    if(this.roleId=='13'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Abordaje", text: "Abordaje", image: "assignment_turned_in" },
        { 'Estado': false, link: "Consulta abordaje", text: "Consulta abordaje", image: "assignment" },
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
      //  { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
        { 'Estado': false, link: "Reporteria", text: "Reporteria", image: "analytics" },
        { 'Estado': false, link: "Aumento de cupo", text: "Aumento de cupo", image: "add_card" },
        { 'Estado': false, link:"Actualizar",text:"Actualizar Informacion",image:"assignment_turned_in"},
        { 'Estado': false, link: "Desbloquear", text: "Desbloquear", image: "source" },
        { 'Estado': false, link: "Cajas", text: "Cajas", image: "face" },
      )
    }
    //jefatura de riesgos
    if(this.roleId=='14'){
      this.newHome.push(
        { 'Estado': false, link: "Gestión de clientes", text: "Gestión de clientes", image: "groups" },
      )
      this.homepagecards.push(
        { 'Estado': false, link: "Abordaje", text: "Abordaje", image: "assignment_turned_in" },
        { 'Estado': false, link: "Consulta abordaje", text: "Consulta abordaje", image: "assignment" },
        { 'Estado': false, link: "Perfil asesor", text: "Perfil asesor", image: "person" },
        { 'Estado': false, link: "Cotizar", text: "Cotizar", image: "fact_check" },
      //  { 'Estado': false, link: "Plan de alivio", text: "Plan de alivio", image: "create" },
        { 'Estado': false, link: "Visor", text: "Visor", image: "face" },
        { 'Estado': false, link: "Reporteria", text: "Reporteria", image: "analytics" },
        { 'Estado': false, link: "Aumento de cupo", text: "Aumento de cupo", image: "add_card" },
        { 'Estado': false, link:"Actualizar",text:"Actualizar Informacion",image:"assignment_turned_in"},
        { 'Estado': false, link: "Desbloquear", text: "Desbloquear", image: "source" },
        { 'Estado': false, link: "Cajas", text: "Cajas", image: "face" },
        { 'Estado': false, link: "Convenios", text: "Convenios", image: "handshake" },
      )
    }
  // if(this.roleId == 'establecer un role o usar alguno(?)'){
  //   this.homepagecards.push(
    
  //   );
  // }
    this.showComponent = "Inicio";
    this.soketHandshake();
  }



  check = () => {
    if (!('serviceWorker' in navigator)) {
      throw new Error('No Service Worker support!')
    }
    if (!('PushManager' in window)) {
      throw new Error('No Push API Support!')
    }
  }

  // I added a function that can be used to register a service worker.
  registerServiceWorker = async () => {
    const swRegistration = await navigator.serviceWorker.register('../../assets/scripts/sw.js'); //notice the file name
    return swRegistration;
  }

  requestNotificationPermission = async () => {

    const permission = await (<any>window).Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== 'granted') {
      // throw new Error('Permission not granted for Notification');
      console.log("Error ...")
    }
  }

  showLocalNotification = (title, body, swRegistration) => {
    const options = {
      body,
      icon: '../../assets/img/Diunsa-logo.png'
      // here you can add more properties like icon, image, vibrate, etc.
    };
    swRegistration.showNotification(title, options);
  }
  
  async soketHandshake() {
    this.check();
    const swRegistration = await this.registerServiceWorker();
    const permission = await this.requestNotificationPermission();
    this.socket = io.connect(this.baseNode + '/', { transports: ['websocket'] });
    this.socket.on('connect', () => {
      this.socket.emit('request', JSON.stringify({ office: localStorage.getItem("tienda") ?   localStorage.getItem("tienda") : '0001', identificacion: "000" }));
    });
    this.socket.on('response', data => {
      var datos = { ...data };
      let amount = this._decimalPipe.transform(datos.Cupo, '1.0-0');
      this.showLocalNotification('Cliente Nuevo !', 'Caja: ' +datos.Caja  + '\n' + 'Cliente: ' +datos.NombreCliente+ '\n'+ 'Cupo: L ' +amount+'\n',  swRegistration);
    });
    this.socket.on('resultadometamap',data=>{
      this.hp.cambioValorSocket(data);
    });
  }
  evalSide(){
    this.showSide=this.screenWidth > 840?true:false
    return this.showSide
  }

  
  showContent(e) {

    this.showComponent = e

  }


  
  resultFormInitial(form) {
    if (form) {
      this.showContent("Abordaje");
      // this.requestId = form.request_id
      // this.askState(form);
      setTimeout(() => {
        this.requestUpdate.next({...form});
      }, 500);
      
    }
  }


  imageReturn(val) : string{
    let res='/assets/img/'+val+'.png';

     return res
    
  }

  evalbkg2() : object{
    if(this.showComponent==="Inicio"){
      let res={"background": "white no-repeat ", "background-position": "right bottom" }
      //url('/assets/img/home.png') 
      return res
    }else{
      let res={"background": "white  no-repeat", "background-position": "right bottom" }
      //url('../assets/img/"+img+".png')
      return res
    }
  }

  
}

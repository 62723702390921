import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { MatTabGroup } from '@angular/material/tabs';
declare var io: any;

export interface DialogData {
  data: any;
}
@Component({
  selector: 'app-feedrequests',
  templateUrl: './feedrequests.component.html',
  styleUrls: ['./feedrequests.component.css']
})
export class FeedrequestsComponent implements OnInit, OnDestroy {
  baseNode = environment.baseNode;
  feeditems: Array<object>;
  observable
  socket;
  myRequest: Array<object> = [];
  takedSol;
  itemToRelase
  index2Relase
  loading = false;
  @ViewChild("tabsFeed", { static: false }) tabsFeed: MatTabGroup;
  @Output() formResult = new EventEmitter<object>();
  constructor(
    private dataService: DataService,
    public dialogo: MatDialog,
    public dialogReasons: MatDialog,

    private _snackBar: MatSnackBar) {

    this.loading = true;
    this.dataService.getSolicitudes().subscribe(response => {
      this.loading = false;
      if (response) {
        this.feeditems = response.Lista
      }
    });

  }
  ngOnDestroy(): void {
    this.socket.disconnect();
  }

  async ngOnInit() {
 
    this.dataService.load();
    // localStorage.setItem("tienda", "1");
    this.getMyRequest();
    this.soketHandshake();
    
  }
  check = () => {
    if (!('serviceWorker' in navigator)) {
      throw new Error('No Service Worker support!')
    }
    if (!('PushManager' in window)) {
      throw new Error('No Push API Support!')
    }
  }

  // I added a function that can be used to register a service worker.
  registerServiceWorker = async () => {
    const swRegistration = await navigator.serviceWorker.register('../../assets/scripts/sw.js'); //notice the file name
    return swRegistration;
  }

  requestNotificationPermission = async () => {

    const permission = await (<any>window).Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== 'granted') {
      // throw new Error('Permission not granted for Notification');
      console.log("Error ...")
    }
  }

  showLocalNotification = (title, body, swRegistration) => {
    const options = {
      body,
      icon: '../../assets/img/Diunsa-logo.png'
      // here you can add more properties like icon, image, vibrate, etc.
    };
    swRegistration.showNotification(title, options);
  }
  getMyRequest(){
    this.loading = true;
    this.dataService.listaGestor().subscribe(response => {
      this.loading = false;
      if (response) {
        this.myRequest = response.Lista
      }
    });
  }
  // SOKET AND SW Registration 
  async soketHandshake() {
    // this.check();
    // const swRegistration = await this.registerServiceWorker();
    // const permission = await this.requestNotificationPermission();
    this.socket = io.connect(this.baseNode + '/', { transports: ['websocket'] });
    this.socket.on('connect', () => {
      this.socket.emit('request', JSON.stringify({ office: localStorage.getItem("tienda") ? localStorage.getItem("tienda") : '0001', identificacion: "000" }));
    });
    this.socket.on('response', data => {
      var datos = { ...data };
      datos.animation = 1;
      setTimeout(() => {
        datos.animation = 0;
      }, 4000);

      this.dataService.addItemService(datos);
      // this.showLocalNotification('Cliente Nuevo !', 'Caja: ' +datos.Caja  + '\n' + 'Cliente: ' +datos.NombreCliente+ '\n'+ 'Cupo: ' +datos.Cupo+ ' L'+'\n',  swRegistration);
    });
  }

  evalStateSimbol(item) {
    switch (item) {
      case 1:
        return "check"
      case 2:
        return "warning"
      case 3:
        return "error"
      default:
        return "check"
    }
  }
  checkStateItem(item) {
    switch (item.Estado) {
      case 1:
        return "Disponible"
      case 2:
        return "Tomada"
      case 3:
        return "Cliente rechaza"
      default:
        return "Disponible"
    }

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
  takeSol(item) {
    this.dialogConfirm("¿ Confirma que desea tomar esta solicitud ?", 1);
    this.takedSol = item;
    // this.formResult.emit(item);
  }
  resumeSol(item) {
    this.changeStateSol(item,2,2);
  }
  relase(item, index) {
    this.index2Relase = index
    this.itemToRelase = item;
    this.dialogConfirm("¿ Confirma que desea liberar esta solicitud ?", 2);
  }
  public goTake() {
    const tabGroup = this.tabsFeed;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex + 1) % tabCount;
  }
  public goFeed() {
    const tabGroup = this.tabsFeed;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex - 1) % tabCount;
  }
  dialogConfirm(text, confimType): void {
    this.dialogo
      .open(ConfirmdialogComponent, {
        data: text
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          if (confimType === 1) this.confirmedTake();
          if (confimType === 2) this.confirmedRelase();
        }
      });
  }
  emitTakeEvent(item) { 
    // item.office = "1";
    item.taked = true;
    this.dataService.relaseItem(item).subscribe(response => {
      if (response.error === 0) {
        // this.myRequest.splice(this.index2Relase, 1);
        // this.openSnackBar("Has liberado la solicitud ", 'Cerrar');
      } else {
        this.openSnackBar("Error en node server", 'Cerrar');
      }
    });
  }
  confirmedRelase() {
    this.itemToRelase.taked = "1";
    // this.takedSol.taked = false;
    this.changeStateSol(this.itemToRelase,1,3);

  }
  changeStateSol(item,state,emit) { 
    this.loading=true;
    let dts = {
      "Id": item.Id,
      "IdEstado": state
    }
    this.dataService.realTimeUpdate(dts).subscribe(response => {
      this.loading=false;
      if (response.IdError === 0) { 
        if(emit==1){
          this.emitTakeEvent(this.takedSol);
        }
        if(emit==2){
          this.formResult.emit(item);
        }
        if(emit==3){
          this.emitTakeEvent(this.takedSol);
          this.getMyRequest();
        }
        this.openSnackBar("El estado de la solicitud ha cambiado. ", 'Cerrar');
      } else {
        this.openSnackBar("Error en cambiar estado de la solicitud.", 'Cerrar');
      }
    });
  }

  confirmedTake() { 
    // this.myRequest.push(this.takedSol);
    this.changeStateSol(this.takedSol,5,1);
    this.goTake();
    this.openSnackBar("Has tomado la solicitud en caja " + this.takedSol.Caja, 'Cerrar');
    setTimeout(() => {
      this.getMyRequest();
    }, 1000);
  }

  irPopUpInfo(data1, index) {
    this.index2Relase = index;
    const dialogRef = this.dialogReasons.open(popUpReason, {
      width: '450px',
      height: '',
      data: { data: data1 }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.goFeed();
        this.getMyRequest();
        setTimeout(() => {
          this.dataService.load();
        }, 1000);
      }else{
       
      }
    });
  }
}



@Component({
  selector: 'popUpReason',
  templateUrl: 'popUpReason.html',
  styleUrls: ['./feedrequests.component.css']
})
export class popUpReason implements OnInit {
  waitSend
  reasonsList = [{ "id": 2, "label": "No le interesa." }, { "id": 2, "label": "No tiene tiempo." }, { "id": 3, "label": "Otra." }]
  reasons
  // imageSrc: string = 'assets/img/default-user.png';
  dataReasons: FormGroup = this.formBuilder.group({
    reason: ['', [Validators.required,]],
  });
  constructor(
    public dialogRef: MatDialogRef<popUpReason>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private dataService:DataService, private _snackBar: MatSnackBar, public dialogo: MatDialog) {
  }

  ngOnInit() {
    this.dataService.catalogos().subscribe(data => {
      if (data.IdError == 0) {
        for (let e in data.Lista) {
          if (data.Lista[e].TipoCatalogo == 'RechazoAbordajeCaja') {
            this.reasons = data.Lista[e].Catalago;
          }
 
        }
      }
    });
  }
  close(): void {
    
  }
  sendReason(): void {
    // alert("ok")
    this.changeStateSol(this.data.data)
    // this.dialogRef.close(undefined);
  }
  changeStateSol(item) {
    let dts = {
      "Id": item.Id,
      "IdEstado": 3,
      "IdRechazo": this.dataReasons.value.reason
    }
    this.dataService.sendRechazo(dts).subscribe(response => {
      if (response.IdError === 0) { 
        this.dialogRef.close(true);
      } else {
        this.openSnackBar("Error en estado de solicitud ", 'Cerrar');
      }
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

}
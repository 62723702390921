import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface metamap {
  identityId?: string,
  verificationId?:string
}
@Injectable({
  providedIn: 'root'
})
export class HelperService {

 private valor = new BehaviorSubject<boolean>(false);
 private socket = new BehaviorSubject<any>({});
 private metamap = new BehaviorSubject<metamap>({});
 public cambiarValor = this.valor.asObservable();
 public cambiarSocket = this.socket.asObservable();
 public cambiarMetamap= this.metamap.asObservable();

 constructor() { 
 }

  public cambioValorServicio(v:boolean):void{
    this.valor.next(v);
  }
  public cambioValorSocket(socket:any):void{
    this.socket.next(socket);
  }
  public cambioValorMetamap(metamap:metamap):void{
    this.metamap.next(metamap);
  }

}

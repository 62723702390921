import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-visor-riesgo-documentos',
  templateUrl: './visor-riesgo-documentos.component.html',
  styleUrls: ['./visor-riesgo-documentos.component.css']
})
export class VisorRiesgoDocumentosComponent implements OnInit {

  volverMenuotravez = false;
  @Output() volverMenu?: any | undefined = new EventEmitter();




  mostrarDocumentoFiltrado = false;
  mostrarDocumentoFiltradoFecha = false;


  tablaFactura = false;
  tablaPagare = false;
  tablaPPagos = false;
  tablaSCompra = false;
  tablaCCompra = false;
  tablaTArchivos = false;

  objPagare = []
  objFactura = [];
  objPPagos = [];
  objSCompra = [];
  objCCompra = [];
  objTArchivos = [];

  filtroOpcion: any;

  filtroFecha: Date;
  tipoDocumentos = [
    {
      nombre: 'Factura',
    },
    {
      nombre: 'Pagare',
    },
    {
      nombre: 'Plan de pago',
    },
    {
      nombre: 'Solicitud de compra',
    },
    {
      nombre: 'Contrato de compra',
    },
    {
      nombre: 'Ver todos los archivos de compras',
    },
  ]
  documento: any;
  nombreCliente: any;
  generarConstancia: FormGroup;
  comprasFiltro: FormGroup;
  rangeForm!: FormGroup;
  estadosCuenta = [];
  documentosFiltadosFecha = [];
  filtroDisplay1 = [];
  dataDisplay = [];
  datas: any;

  tipoConstancia: any;
  ciudadConstancia: any;
  nombreCiudadContancia: any

  currentPage = 1;
  itemsPerPage = 6;
  totalItems: number;
  totalPages: number;
  // displayedItems: any[] = []; // Almacena los datos del API

  @Input() informacionCliente: any;
  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,

  ) { }

  ngOnInit() {
    this.ciudadConstancia = this.informacionCliente[9]
    this.tipoConstancia = this.informacionCliente[8]
    this.documento = this.informacionCliente[0].InfoPersonal.Documento
    let nombreCapitalizable = this.capitalizer(this.informacionCliente[0].InfoPersonal.NombreCompleto)
    this.nombreCliente = nombreCapitalizable
    this.datas = this.informacionCliente[7];

    this.objFactura = this.informacionCliente[10];
    this.objFactura.sort()
    this.objPPagos = this.informacionCliente[5];
    this.objPPagos.sort()
    this.objSCompra = this.informacionCliente[6];
    this.objSCompra.sort()
    this.objCCompra = this.informacionCliente[4];
    this.objCCompra.sort()
    this.objPagare = this.informacionCliente[3];
    this.objPagare.sort()


    this.crearObjetoTodosLosArchivos(this.objPPagos)
    this.crearObjetoTodosLosArchivos(this.objSCompra)
    this.crearObjetoTodosLosArchivos(this.objCCompra)
    this.crearObjetoTodosLosArchivos(this.objFactura)
    this.crearObjetoTodosLosArchivos(this.objPagare)

    this.comprasFiltro = this.fb.group({
      filtroCompras: ['', [Validators.required,]]
    });
    this.generarConstancia = this.fb.group({
      tipoConstancia: ['', [Validators.required,]],
      institucion: ['', [Validators.required,]],
      ciudad: ['', [Validators.required,]],

    });
    this.rangeForm = this.fb.group({
      fechaInicio: ['', Validators.required],
      fechaFin: ['', Validators.required],
    });
    this.cargarInformacion();

  }

  capitalizer(value) {
    return value.toLowerCase().replace(/(^|\s)\S/g, match => {
      return match.toUpperCase();
    })
  }
  captFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  ejecutarEnlace(url) {
    window.open(url);
  }

  get valueFechaInicio() {
    return new Date(this.rangeForm.controls['fechaInicio'].value)
  }
  get valueFechaFin() {
    return new Date(this.rangeForm.controls['fechaFin'].value)
  }

  crearObjetoTodosLosArchivos(arreglo: any) {
    if (arreglo.length > 0) {

      for (let index = 0; index < arreglo.length; index++) {
        const element = arreglo[index];

        let data = {
          FechaControl: element.FechaControl,
          Identificacion: element.Identificacion,
          TipoDocumento: element.TipoDocumento,
          Url: element.Url,
        }

        this.objTArchivos.push(data)


      }
    }
  }

  valorInstitucion(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^a-zA-Z ]/gi, '');
    if (num.length > 100) {
      var num = num.slice(0, 100);
    }
    this.generarConstancia.controls['institucion'].setValue(num);
  }

  cambioOpcion(data) {

    this.nombreCiudadContancia = data

  }


  volver() {
    this.volverMenuotravez = true;
    let data = {
      cerrar: this.volverMenuotravez
    };
    this.volverMenu!.emit(data);
  }

  btnAnterior() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.actualizar();
    }
  }

  btnSiguiente() {

    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.actualizar();
    }
  }
  btnAnteriorFiltro() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.actualizarFiltro();
    }
  }

  btnSiguienteFiltro() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.actualizarFiltro();
    }
  }

  actualizar() {
    this.loadItems(this.currentPage);
  }
  actualizarFiltro() {
    this.loadItemsFiltroFecha(this.currentPage);
  }

  cargarInformacion() {
    this.totalItems = this.datas.length;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.loadItems(this.currentPage);

  }
  cargarInformacionFiltro() {
    this.totalItems = this.documentosFiltadosFecha.length;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.loadItemsFiltroFecha(this.currentPage);
  }

  loadItems(page: number) {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.dataDisplay = this.datas.slice(startIndex, endIndex);
  }
  loadItemsFiltroFecha(page: number) {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.filtroDisplay1 = this.documentosFiltadosFecha.slice(startIndex, endIndex);


  }
  //filtro fecha
  nombreDocumento: string;
  fechaDocumentos: string;

  aplicarFiltro() {
    if (!this.rangeForm.controls['fechaInicio'].value || !this.rangeForm.controls['fechaFin'].value) {
      this.mostrarDocumentoFiltradoFecha = false;

      return this.dataDisplay;

    } else {
      this.mostrarDocumentoFiltradoFecha = true;
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.documentosFiltadosFecha = this.datas.filter(x => {

        const xDate = new Date(x.FechaControl);
        this.currentPage = 1;
        return xDate >= this.rangeForm.controls['fechaInicio'].value && xDate <= this.rangeForm.controls['fechaFin'].value
      });
    }
    this.cargarInformacionFiltro();
    this.cerrarModal();

  }
  quitarFiltro() {
    if(this.rangeForm){
      this.rangeForm.reset();
      this.cargarInformacion();
      this.mostrarDocumentoFiltradoFecha = false;
    }
  }

  get filtroFechaSeleccionada() {
    if (!this.rangeForm.controls['fechaInicio'].value || !this.rangeForm.controls['fechaFin'].value) {
      return this.dataDisplay;
    } else {
      this.loadItems(this.currentPage);
      return this.dataDisplay.filter(x => x.fecha >= new Date(this.rangeForm.controls['fechaInicio'].value).toDateString() && x.fecha <= new Date(this.rangeForm.controls['fechaFin'].value).toDateString());
    }
  }

  descargarConstancia() {
    //1.documento - 2.tipoContancia - 3.institucion - ciudad
    var dts = this.documento + "/" + this.generarConstancia.value.tipoConstancia.toString() +
      "/" + this.generarConstancia.value.institucion.toString() + "/" + this.generarConstancia.value.ciudad.toString();
    this.dataService.verPdfConstancia(dts).subscribe((res) => {
      if (res.length > 0) {
        const element = document.createElement('a');
        element.setAttribute('href', ' data:application/pdf;base64,' + res);
        element.setAttribute('download', 'Constancia' + '_' + this.documento + '.pdf');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element)
        this.openSnackBar('Descargando...', 'cerrar');
      } else {
        this.openSnackBar('No aplica.', 'cerrar');

      }

    })

    // console.log(this.generarConstancia.value)


  }

  descargarReportePdf() {

    this.dataService.verPdfRepo(this.documento).subscribe(response => {

      if (response.length > 0) {

        const element = document.createElement('a');
        element.setAttribute('href', ' data:application/pdf;base64,' + response);
        element.setAttribute('download', 'Reporte' + '_' + this.documento + '.pdf');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.openSnackBar('Descargando...', 'cerrar');

      } else {

      }
    });
  }

  nombreDocumentoSeleccionado;

  opcionSeleccion(event: any) {
    const selectedValue = event.target.value;
    // Hacer algo con el valor seleccionado
    this.filtroOpcion = selectedValue;
    //variable que muestra la tabla

  }

  filtrarBTn() {
    this.mostrarDocumentoFiltrado = true;
    if (this.filtroOpcion == 'Factura') {
      this.tablaFactura = true
      this.tablaPPagos = false;
      this.tablaSCompra = false;
      this.tablaCCompra = false;
      this.tablaTArchivos = false;
      this.tablaPagare = false;
      this.nombreDocumentoSeleccionado = this.filtroOpcion;

    }
    if (this.filtroOpcion == 'Pagare') {
      this.tablaPagare = true
      this.tablaFactura = false
      this.tablaPPagos = false;
      this.tablaSCompra = false;
      this.tablaCCompra = false;
      this.tablaTArchivos = false;
      this.nombreDocumentoSeleccionado = this.filtroOpcion;

    }
    if (this.filtroOpcion == 'Plan de pago') {

      this.tablaPPagos = true;
      this.tablaFactura = false
      this.tablaSCompra = false;
      this.tablaCCompra = false;
      this.tablaTArchivos = false;
      this.tablaPagare = false;
      this.nombreDocumentoSeleccionado = this.filtroOpcion;

    }
    if (this.filtroOpcion == 'Solicitud de compra') {
      this.tablaSCompra = true;
      this.tablaFactura = false;
      this.tablaPPagos = false;
      this.tablaCCompra = false;
      this.tablaTArchivos = false;
      this.tablaPagare = false;
      this.nombreDocumentoSeleccionado = this.filtroOpcion;
    }
    if (this.filtroOpcion == 'Contrato de compra') {
      this.tablaCCompra = true;
      this.tablaFactura = false
      this.tablaPPagos = false;
      this.tablaSCompra = false;
      this.tablaTArchivos = false;
      this.tablaPagare = false;
      this.nombreDocumentoSeleccionado = this.filtroOpcion;

    }
    if (this.filtroOpcion == 'Ver todos los archivos de compras') {
      this.tablaTArchivos = true;
      this.tablaFactura = false
      this.tablaPPagos = false;
      this.tablaSCompra = false;
      this.tablaCCompra = false;
      this.tablaPagare = false;
      this.nombreDocumentoSeleccionado = this.filtroOpcion;
    }


    //Validar el arreglo  
    //validar que tipo de info en relacion con documentos se mostrara
    //llenarlo con info por cada documento
  }

  generarFiltro() {
    this.abrirModal()
  }

  @ViewChild('rangeModal') modalTemplate?: TemplateRef<any>;

  abrirModal(): void {
    const dialogRef = this.dialog.open(this.modalTemplate, {
      width: '30%',
      height: '30%'
    });

  }

  cerrarModal() {
    this.dialog.ngOnDestroy();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }



}

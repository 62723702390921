import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-visor-riesgo',
  templateUrl: './visor-riesgo.component.html',
  styleUrls: ['./visor-riesgo.component.css']
})
export class VisorRiesgoComponent implements OnInit {

  filtros = [];
  filtroSeleccionado = 0;
  nombrefiltro = ""
  formulario = false;
  isLoading = false;
  consultaDocumento: FormGroup;
  consultaNomApe: FormGroup;
  consultaTel: FormGroup;
  consultaCor: FormGroup;
  nombre = "";
  documento = "";
  clientes = [];
  aumentoData: any;


  clienteEncontrado = false;
  listaCliente = false;

  abrirInfoCliente = false;
  abrirDocumentos = false;
  abrirAumentoCupo = false;
  documentoEncontrado = false;
  aumentoCupoPosible = false;

  municipio:any;
  colonia:any;
  departamento:any;
  genero = [];
  estadoCivil = [];
  actividadEconomica = [];
  estadoSolicitud = [];
  tipoConstancia = [];
  ciudadConstancia = [];


  idPorValor = [];



  data: any;
  informacionPrimaria: any;
  informacionSecundaria: any;
  informacionHistorial: any;

  expediente = [];
  documentos = [];

  aumentoActivo = false;


  mensajeError = "";
  constructor(
    private dataServices: DataService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.consultaDocumento = this.fb.group({
      documento: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
    });
    this.consultaNomApe = this.fb.group({
      primerNombre: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      segundoNombre: ['',],
      primerApellido: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      segundoApellido: ['',]
    });
    this.consultaTel = this.fb.group({
      telefono: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]]
    });
    this.consultaCor = this.fb.group({
      correo: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]]
    });

    this.obtenerCatalogos();
  }

  cambioOpcion(data) {

    this.listaCliente = false;
    this.resetearValores(data.Id)
    this.filtroSeleccionado = data.Id;
    this.nombrefiltro = data.Descripcion;
    this.nombrefiltro = this.nombrefiltro.toLocaleLowerCase();
    this.formulario = true;

  }

  volver() {
    this.resetBool();
    //Resetear local storage
  }

  consultarCliente(value: any) {
    this.isLoading = true;
    //Se Consume servicio consulta
    if (value == 1) {
      let data = {
        documento: this.consultaDocumento.controls['documento'].value
      }

      this.consultaCliente(data);

    } else {
      let data = {
        TipoFiltro: 0,
        PrimerNombre: this.consultaNomApe.controls['primerNombre'].value,
        SegundoNombre: this.consultaNomApe.controls['segundoNombre'].value,
        PrimerApellido: this.consultaNomApe.controls['primerApellido'].value,
        SegundoApellido: this.consultaNomApe.controls['segundoApellido'].value,
        Correo: this.consultaCor.controls['correo'].value,
        Telefono: this.consultaTel.controls['telefono'].value
      }
      if (value == 2) {
        data.TipoFiltro = 1;
        this.consultaClienteOtros(data);
      }
      else if (value == 3) {
        data.TipoFiltro = 2;

        this.consultaClienteOtros(data);
      }
      else if (value == 4) {
        data.TipoFiltro = 3;

        this.consultaClienteOtros(data);
      }
    }



  }

  abrirInformacionCliente() {
    this.abrirInfoCliente = !this.abrirInfoCliente;
  }
  abrirAumentoCupoCliente() {
    this.abrirAumentoCupo = !this.abrirAumentoCupo;
  }
  abrirDocumentosCliente() {
    this.abrirDocumentos = !this.abrirDocumentos;
  }

  resetBool() {
    this.nombre = "";
    this.documento = "";
    this.filtroSeleccionado = 0;
    this.clientes = [];
    this.clienteEncontrado = false;
    this.aumentoActivo = false;
    this.documentoEncontrado = false;
    this.aumentoCupoPosible = false;
    this.listaCliente = false;
    this.consultaNomApe.reset();
    this.consultaTel.reset();
    this.consultaCor.reset();
    this.consultaDocumento.reset();
    this.municipio = "";
    this.departamento = "";
    this.colonia = "";
  }

  volverOpciones(data: any) {
    if (data.cerrar) {

      this.abrirInfoCliente = false;
      this.abrirDocumentos = false;
      this.abrirAumentoCupo = false;
      this.aumentoActivo = false;
      this.documentoEncontrado = false;
      this.aumentoCupoPosible = false;
      this.clienteEncontrado = true;



    }
  }

  consultaCliente(data: any) {
    this.isLoading = true;
    this.dataServices.consultarClienteDocumento(data).subscribe((res) => {
      if (res.IdError == 0 && res.Activo) {
        this.documento = data.documento;
        let nombreCapitalizado = this.capitalizer(res.NombreCliente);
        this.nombre = nombreCapitalizado;
        this.obtenerInfoCliente(this.documento);

      } else {
        //Si no
        this.clienteEncontrado = false;
        //Y se abre el modal indicando que no existe
        this.mensajeError = res.Mensaje;
        this.abrirModal()
        this.isLoading = false;

      }
    });
  }

  capitalizer(value) {
    return value.toLowerCase().replace(/(^|\s)\S/g, match => {
      return match.toUpperCase();
    })
  }

  async consultaClienteOtros(data: any) {
    await this.dataServices.consultarClienteOtrosFiltros(data).subscribe((res) => {
      if (res.IdError == 0) {
        this.clientes = res.ListaClientesVisor;
        if (this.clientes.length > 0) {
          //Mostrar modal con las opciones
          //this.abrirModalListado();
          //Crear una opcion en el modal para elegir ese cliente

          //cuando elija
          // this.clienteEncontrado = true;
          this.filtroSeleccionado = 0;
          this.listaCliente = true;
          this.isLoading = false;
          this.clientes.forEach(element => {
            let nombre1 = element.PrimerNombre
            let nombre2 = element.SegundoNombre
            let apellido1 = element.PrimerApellido
            let apellido2 = element.SegundoApellido


            element.PrimerNombre = this.capitalizer(nombre1);
            element.SegundoNombre = this.capitalizer(nombre2);
            element.PrimerApellido = this.capitalizer(apellido1);
            element.SegundoApellido = this.capitalizer(apellido2);
          });
        } else {
          this.nombre = this.concatName(this.clientes);
          this.clienteEncontrado = true;
          this.documento = this.clientes[0].Documento;
          this.obtenerInfoCliente(this.documento);

        }


      } else {
        //Si no
        this.clienteEncontrado = false;
        //Y se abre el modal indicando que no existe
        this.mensajeError = res.Mensaje;

        this.abrirModal()
        this.isLoading = false;
      }
    });
  }

  obtenerInfoCliente(value) {
    let data = {
      documento: value
    }
    this.dataServices.consultaInformacionCliente(data).subscribe((res) => {
      if (res.IdError == 0) {

        this.informacionPrimaria = res;
        this.obtenerCiudadColonia(this.informacionPrimaria.InfoContacto.IdDepartamento, this.informacionPrimaria.InfoContacto.IdCiudad, this.informacionPrimaria.InfoContacto.IdColonia);

        this.obtenerInformacionSecundaria(data)
      } else {
        //Si no
        // this.clienteEncontrado = false;
        //Y se abre el modal indicando que no existe
        // this.mensajeError = res.Mensaje;
        // this.abrirModal()
        this.isLoading = false;
        this.openSnackBar(res.Mensaje, 'cerrar');


      }

    })
  }

  obtenerInformacionSecundaria(data) {
    this.dataServices.consultaInformacionSecundariaCliente(data).subscribe((res) => {
      if (res.IdError == 0) {
        this.informacionSecundaria = res;
        //por ahora
        this.obtenerHistorialCR(data);

      } else {
        this.isLoading = false;
        this.openSnackBar(res.Mensaje, 'cerrar');

      }
    })
  }

  obtenerHistorialCR(data) {
    this.dataServices.consultaInformacionHistorialCliente(data).subscribe((res) => {
      if (res.IdError == 0) {
        this.informacionHistorial = res;

        //por ahora
        let genero = this.obtenerValorArreglo(this.informacionPrimaria.InfoPersonal.IdGenero, this.genero)
        let estadoCivil = this.obtenerValorArreglo(this.informacionPrimaria.InfoPersonal.EstadoCivil, this.estadoCivil)
        let activEconomica = this.obtenerValorArreglo(this.informacionPrimaria.InfoCrediticia.ActividadEconomica, this.actividadEconomica)
        let estadoSol = this.obtenerValorArreglo(this.informacionSecundaria.InfoCredito.Estado, this.estadoSolicitud)

        let data2 = [this.informacionPrimaria,
        this.informacionSecundaria,
        this.informacionHistorial,
          genero, this.municipio, estadoCivil, activEconomica, estadoSol, this.colonia, this.departamento
        ]
        
        this.data = data2;
        this.isLoading = false;
        this.listaCliente = false;
        this.clienteEncontrado = true;

      } else {
        this.isLoading = false;
        this.openSnackBar(res.Mensaje, 'cerrar');

      }
    })
  }

  obtenerCiudadColonia(departamentoId, ciudadId, coloniaId) {

    this.dataServices.departamentos().subscribe((res) => {
      if (res.IdError == 0) {
        let departamentos: any = res.Lista;
        if (departamentos.length > 0) {
          departamentos.forEach(element => {
            if (departamentoId == element.Id) {
              this.departamento = element.Nombre;
            } 
          });
        } else{
        }
      }
    })
    let dataCiu = {
      Id: ciudadId,
      Nombre: "",
      IdDepartamento: departamentoId,
      Estado: true
    }
    this.dataServices.getCiudades(dataCiu).subscribe((res) => {
      if (res.IdError == 0) {
      let ciudades: any = res.Lista;
        if (ciudades.length > 0) {
          ciudades.forEach(element => {
            if (ciudadId == element.Id) {
              this.municipio = element.Nombre;
            }
          });
        }else{
          this.municipio = ""
        }
      }

    })
    let dataCol = {
      Id: coloniaId,
      Nombre: "",
      IdCiudad: ciudadId,
      Estado: true
    }
    this.dataServices.getColonia(dataCol).subscribe((res) => {
      if (res.IdError == 0) {
      let colonias: any = res.Lista;
        if (colonias.length > 0) {
          colonias.forEach(element => {
            if (coloniaId == element.Id) {
              this.colonia = element.Nombre;
            } 
          });
        } 
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  obtenerValorArreglo(value, arreglo): string {
    let valor;
    this.idPorValor = arreglo;
    this.idPorValor.forEach(element => {
      if (element.Id == value) {
        valor = element.Descripcion
      }
    });
    return valor;
  }

  obtenerCatalogos() {
    this.dataServices.catalogos().subscribe((res) => {
      if (res.IdError == 0) {
        for (var e in res.Lista) {
          if (res.Lista[e].TipoCatalogo == "TipoFiltroVisorRiesgo") {
            this.filtros = res.Lista[e].Catalago

          }
          if (res.Lista[e].TipoCatalogo == "EstadoCivil") {
            this.estadoCivil = res.Lista[e].Catalago
          }
          if (res.Lista[e].TipoCatalogo == "ActividadEconomica") {
            this.actividadEconomica = res.Lista[e].Catalago

          }
          if (res.Lista[e].TipoCatalogo == "Estado") {
            this.estadoSolicitud = res.Lista[e].Catalago

          }
          if (res.Lista[e].TipoCatalogo == "Municipio") {
            this.municipio = res.Lista[e].Catalago
          }
          if (res.Lista[e].TipoCatalogo == "Sexo") {
            this.genero = res.Lista[e].Catalago

          }
          if (res.Lista[e].TipoCatalogo == "TipoConstancia") {
            this.tipoConstancia = res.Lista[e].Catalago

          }
          if (res.Lista[e].TipoCatalogo == "CiudadConstancia") {
            this.ciudadConstancia = res.Lista[e].Catalago

          }
        }
        this.filtros.sort((a, b) => a.Id - b.Id)
      } else {
        this.openSnackBar(res.Mensaje, 'cerrar');
      }

    })
  }


  resetearValores(x) {

    if (x == '1') {

      this.consultaNomApe.reset();
      this.consultaTel.reset();
      this.consultaCor.reset();

    }
    else if (x == '2') {

      this.consultaDocumento.reset();
      this.consultaTel.reset();
      this.consultaCor.reset();
    }
    else if (x == '3') {

      this.consultaNomApe.reset();
      this.consultaDocumento.reset();
      this.consultaCor.reset();

    }
    else if (x == '4') {

      this.consultaNomApe.reset();
      this.consultaTel.reset();
      this.consultaDocumento.reset();


    }
  }

  concatName(data): string {
    if (data.SegundoNombre == '' || data.SegundoNombre == null) data.SegundoNombre = ''
    if (data.SegundoApellido == '' || data.SegundoApellido == null) data.SegundoApellido = ''

    return data.PrimerNombre + ' ' + data.SegundoNombre + ' ' + data.PrimerApellido + ' ' + data.SegundoApellido
  }


  //modal
  @ViewChild('modalTemplate') modalTemplate?: TemplateRef<any>;

  abrirModal(): void {
    const dialogRef = this.dialog.open(this.modalTemplate, {
      width: '30%',
      height: '35%'
    });

  }

  cerrarModal() {
    this.dialog.ngOnDestroy();
  }


  //modal-multicliente
  @ViewChild('modalTemplate2') modalTemplate2?: TemplateRef<any>;

  abrirModalListado(): void {
    const dialogRef = this.dialog.open(this.modalTemplate2, {
      width: '20%',
      height: '20%'
    });

  }

  cerrarModal2() {
    this.dialog.ngOnDestroy();
  }

  despuesDeEligir(data) {
    this.isLoading = true;
    this.nombre = this.concatName(data);
    this.documento = data.Documento;
    this.obtenerInfoCliente(this.documento);

    // this.cerrarModal2();
  }

  obtenerDocumentos() {
    this.aumentoCupoPosible = true;
    this.aumentoActivo = true;
    this.documentoEncontrado = true
    this.dataServices.consultaDocumentos({ documento: this.documento }).subscribe((res) => {
      if (res.IdError == 0) {
        this.aumentoActivo = false;
        this.documentoEncontrado = false;
        let pagare = [];
        let factura = [];
        let contrato = [];
        let planPagos = [];
        let solicitudCompra = [];
        let estadoCuenta = [];
        this.documentos = res.Documentos;
        this.documentos.forEach(element => {
          if (element.TipoDocumento == 'Solicitud de compra venta') {
            solicitudCompra.push(element)
          }
          if (element.TipoDocumento == 'Plan de pago') {
            planPagos.push(element)
          }
          if (element.TipoDocumento == 'Pagare') {
            pagare.push(element)
          }
          if (element.TipoDocumento == 'Factura') {
            factura.push(element)
          }
          if (element.TipoDocumento == 'Contrato de compra venta') {
            contrato.push(element)
          }
          if (element.TipoDocumento == 'ESTADO DE CUENTA') {
            estadoCuenta.push(element)
          }
        })
        let data2 = [
          this.informacionPrimaria,
          this.informacionSecundaria,
          this.informacionHistorial,
          pagare, contrato, planPagos, solicitudCompra, estadoCuenta,
          this.tipoConstancia, this.ciudadConstancia, factura
        ]
        this.expediente = data2;
        this.abrirDocumentosCliente();
        this.aumentoCupoPosible = false;
        this.aumentoActivo = false;
        this.documentoEncontrado = false
      } else {
        this.documentoEncontrado = true;
        this.aumentoCupoPosible = false;
        this.aumentoActivo = false;
        this.openSnackBar(res.Mensaje, 'cerrar');
      }
    })
  }

  obtenerDatosAumento() {
    this.aumentoCupoPosible = true;
    this.aumentoActivo = true;
    this.documentoEncontrado = true

    this.dataServices.consultaAumentoCupo({ documento: this.documento }).subscribe((res) => {
      if (res.IdError == 0) {

        let activEconomica = this.obtenerValorArreglo(res.DatosFormulario.ActividadEconomica, this.actividadEconomica)
        let data2 = {
          documento: this.documento,
          data: res.DatosFormulario,
          idLogAumentoCupo: res.IdLogAumentoCupo,
          actividadEconomica: activEconomica
        }
        this.aumentoCupoPosible = false;
        this.aumentoData = data2;
        this.aumentoActivo = false;
        this.abrirAumentoCupoCliente();
        this.aumentoCupoPosible = false;
        this.aumentoActivo = false;
        this.documentoEncontrado = false
      } else {
        this.aumentoCupoPosible = true;
        this.aumentoActivo = false;
        this.documentoEncontrado = false;
        this.openSnackBar(res.Mensaje, 'cerrar');
      }
    })
  }


  valorDocumento(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.consultaDocumento.controls['documento'].setValue(num);

  }

  get requiereDocument() {
    return this.consultaDocumento.get('documento')?.hasError('required')
      && this.consultaDocumento.controls['documento'].touched
  }

  get lenghtDocument() {
    return this.consultaDocumento.get('documento')?.hasError('minlength')
  }

  //Keyup
  valorNombre(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^A-Za-zñÑáéíóúÁÉÍÓÚ]/g, "");
    if (num.length > 100) {
      var num = num.slice(0, 100);
    }
    this.consultaNomApe.controls['primerNombre'].setValue(num);
  }
  get lenghtPrimerNombre() {
    return this.consultaNomApe.get('primerNombre')?.hasError('minlength')
  }
  get requierePrimerNombre() {
    return this.consultaNomApe.get('primerNombre')?.hasError('required')
      && this.consultaNomApe.controls['primerNombre'].touched
  }
  //Keyup
  valorSegundoNombre(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^A-Za-zñÑáéíóúÁÉÍÓÚ]/g, "");
    if (num.length > 100) {
      var num = num.slice(0, 100);
    }
    this.consultaNomApe.controls['segundoNombre'].setValue(num);
  }

  //Keyup
  valorApellido(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^A-Za-zñÑáéíóúÁÉÍÓÚ]/g, "");
    if (num.length > 100) {
      var num = num.slice(0, 100);
    }
    this.consultaNomApe.controls['primerApellido'].setValue(num);
  }
  get lenghtPrimerApellido() {
    return this.consultaNomApe.get('primerApellido')?.hasError('minlength')
  }
  get requierePrimerApellido() {
    return this.consultaNomApe.get('primerApellido')?.hasError('required')
      && this.consultaNomApe.controls['primerApellido'].touched
  }


  //Keyup
  valorSegundoApellido(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^A-Za-zñÑáéíóúÁÉÍÓÚ]/g, "");
    if (num.length > 100) {
      var num = num.slice(0, 100);
    }
    this.consultaNomApe.controls['segundoApellido'].setValue(num);
  }
  get lenghtSegundoApellido() {
    return this.consultaNomApe.get('segundoApellido')?.hasError('minlength')
  }
  get requiereSegundoApellido() {
    return this.consultaNomApe.get('segundoApellido')?.hasError('required')
      && this.consultaNomApe.controls['segundoApellido'].touched
  }

  //Keyup


  get requiereCorreo() {
    return this.consultaCor.get('correo')?.hasError('required')
      && this.consultaCor.controls['correo'].touched
  }
  get patternCorreo() {
    return this.consultaCor.get('correo')?.hasError('pattern')
      && this.consultaCor.controls['correo']?.touched
  }

  //Keyup
  valorTelefono(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 8) {
      var num = num.slice(0, 8);
    }
    this.consultaTel.controls['telefono'].setValue(num);
  }
  get lenghtTelefono() {
    return this.consultaTel.get('telefono')?.hasError('minlength')
  }
  get requiereTelefono() {
    return this.consultaTel.get('telefono')?.hasError('required')
      && this.consultaTel.controls['telefono'].touched
  }












}

import { Component, OnInit, ViewChild, Injectable, Inject, ElementRef} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import * as _ from 'lodash';
export interface DialogData {
  user: any;
  day: any;
  turnosAb: any;
}
@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.css']
})
export class ConveniosComponent implements OnInit {
  @ViewChild('filePicker', { static: true }) filePickerRef: ElementRef<HTMLInputElement>;

  dataIntra: FormGroup = this.formBuilder.group({
    Convenio: ['', [Validators.required]],
    Archivo:['', [Validators.required]]
  });

  idConvenio:number;
  botonReporte:boolean = false;
  archivoError: string;
  nombreArchivo:string;
  isArchivoSaved: boolean;
  mensajeError:string = '';
  cardArchivo: string;
  tipoArchivo: string;
  ListConvenios : Array<any>=[];
  constructor(private _snackBar: MatSnackBar, private formBuilder: FormBuilder, private dataService: DataService, public dialogo: MatDialog, public dialog: MatDialog) { }
  waitSend = false
  
  ngOnInit() {
  this.listarConvenios();
  }


  nuevoConvenio() {
    const dialogRef = this.dialog.open(popUpConvenio, {
      width: '450px',
      height: '',
     
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
         let dat={
          Nombre:result
         }
         this.dataService.nuevoConvenio(dat).subscribe(response => {
          
          if(response.IdError ==0){
            this.openSnackBar('Convenio registrado exitosamente.', 'Cerrar');
          }else{
            this.openSnackBar(response.Mensaje, 'Cerrar');
          }
          this.listarConvenios();
         });
      }
    });
  }
  cargarData() {

    this.idConvenio = this.dataIntra.get('Convenio').value;
     let data = {
      IdConvenio: this.dataIntra.get('Convenio').value,
      Archivo:this.tipoArchivo
     }
     this.waitSend = true;
     this.dataService.cargarDataConvenio(data).subscribe(response => {
     this.waitSend = false;
     
      if(response.IdError == 0){
       this.dataIntra.reset(); 
       this.botonReporte = true;
       this.isArchivoSaved = false;
       this.openSnackBar(response.Mensaje, 'Cerrar');
      } else {
        this.mensajeError = response.Mensaje;
        this.isArchivoSaved = false;
        this.tipoArchivo = '';
        this.nombreArchivo = '';
        this.dataIntra.get('Archivo').setValue("");
      }
     });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  async clickNuevoArchivo(){
    this.filePickerRef.nativeElement.click();
  }
  
  cambioConvenio(){
    this.mensajeError = '';
    this.idConvenio = null;
    this.botonReporte = false;
  }
  fileChangeEvent(fileInput: any) {
    this.archivoError = null;
    this.mensajeError = '';
    this.botonReporte = false;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['text/plain'];
      if (fileInput.target.files[0].size > max_size) {
        this.archivoError = 'Tamaño máximo permitido' + max_size / 1000 + 'Mb';
        this.removeArchivo();
        return false;
      }
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.archivoError = 'Solo es permitido un archivo .txt';
        this.removeArchivo();
        return false;
      }
      let extension = fileInput.target.files[0].name.substr(-3,3);
      if(extension!='txt'){
      this.archivoError = 'Solo es permitido un archivo .txt';
      this.removeArchivo();
      return false;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
       this.tipoArchivo = e.target.result;
       this.nombreArchivo = fileInput.target.files[0].name;
       this.isArchivoSaved = true;
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removeArchivo() {
    this.isArchivoSaved = false;
    this.tipoArchivo = '';
    this.nombreArchivo = '';
    this.mensajeError = '';
    this.dataIntra.get('Archivo').setValue("");
  }

 listarConvenios(){
  this.dataService.listaConvenio().subscribe(data => {
    if (data.IdError == 0) {
          this.ListConvenios = data.Lista;
    }
  });
 }

 descargarReporte(){
  
 // if(this.idConvenio !=null){
  let dat = {
    Id: this.idConvenio
  }
  this.waitSend = true;
  this.dataService.descagaReporte(dat).subscribe(data => {
    this.waitSend = false;
    if (data.IdError == 0) {
      const linkSource = `data:application/csv;base64,${data.Archivo}`;
      const downloadLink = document.createElement('a');
      const fileName = 'Reporte.csv';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }else{
      this.openSnackBar(data.Mensaje, 'Cerrar');
    }
  });
// }
}
}

@Component({
  selector: 'popUpConvenio',
  templateUrl: 'popUpConvenio.html',
  styleUrls: ['./convenios.component.css']
})
export class popUpConvenio implements OnInit {
  waitSend
  validate = false;
 
  constructor(
    public dialogRef: MatDialogRef<popUpConvenio>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private dataService: DataService, public dialogo: MatDialog) {


  }
  dataIntra: FormGroup = this.formBuilder.group({

    Nombre: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],

  });
  ngOnInit() {

  }
  sendOkDocs() {
  this.dialogRef.close(this.dataIntra.value.Nombre);
  }

  onNoClick2(): void {
    this.dialogRef.close(undefined);
  }

}
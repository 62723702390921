import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-visor-riesgo-info-cliente',
  templateUrl: './visor-riesgo-info-cliente.component.html',
  styleUrls: ['./visor-riesgo-info-cliente.component.css']
})
export class VisorRiesgoInfoClienteComponent implements OnInit {

  volverMenuotravez = false;
  @Output() volverMenu?: any | undefined = new EventEmitter();

  informacionPersonal: any;
  informacionContacto: any;
  informacionLaboral: any;
  informacionCrediticia: any;
  informacionSolicitudes: any;

  nombre: any;

  //

  infoCredito: any;
  infoDetallePago: any;
  infoExpOriginacion: any;

  //compras - recaudos

  recaudos: any;
  compras: any;

  //

  infoId = []

  @Input() informacionCliente: any;
  constructor(
    private dataServices: DataService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    let nombreCapitalizable = this.capitalizer(this.informacionCliente[0].InfoPersonal.NombreCompleto)
    this.nombre = nombreCapitalizable;
    this.informacionPersonal = this.informacionCliente[0].InfoPersonal;
    this.informacionContacto = this.informacionCliente[0].InfoContacto;
    this.informacionLaboral = this.informacionCliente[0].InfoLaboral;
    this.informacionCrediticia = this.informacionCliente[0].InfoCrediticia;
    this.informacionSolicitudes = this.informacionCliente[0].InfoHistorialSolicitudes;

    // 

    this.infoCredito = this.informacionCliente[1].InfoCredito;
    for (let index = 0; index < this.infoCredito.length; index++) {
      this.infoCredito[index] = this.infoCredito[index].replace(',','.');
    }
    this.infoDetallePago = this.informacionCliente[1].InfoDetallePago;
    this.infoExpOriginacion = this.informacionCliente[1].InfoExpOriginacion;

    //

    this.compras = this.informacionCliente[2].Compras;

    this.recaudos = this.informacionCliente[2].Recaudos;



    //
    this.infoId.push(this.informacionCliente[3]);
    this.infoId.push(this.informacionCliente[4]);
    this.infoId.push(this.informacionCliente[5]);
    this.infoId.push(this.informacionCliente[6]);
    this.infoId.push(this.informacionCliente[8]);
    this.infoId.push(this.informacionCliente[9]);

  }

  capitalizer(value){
    return value.toLowerCase().replace(/(^|\s)\S/g, match =>{
      return match.toUpperCase();
    })
  }

  consultarCompras() {

    this.abrirModalListado()
  }
  @ViewChild('modalTemplate2') modalTemplate2?: TemplateRef<any>;

  abrirModalListado(): void {
    const dialogRef = this.dialog.open(this.modalTemplate2, {
      width: '50%',
      height: '50%'
    });

  }

  cerrarModal() {
    this.dialog.ngOnDestroy();
  }

  consultarSolicitudes() {

    this.abrirModalListado2()
  }

  @ViewChild('modalTemplate3') modalTemplate3?: TemplateRef<any>;

  abrirModalListado2(): void {
    const dialogRef = this.dialog.open(this.modalTemplate3, {
      width: '50%',
      height: '50%'
    });

  }

  cerrarModal2() {
    this.dialog.ngOnDestroy();
  }




  volver() {
    this.volverMenuotravez = true;
    let data = {
      cerrar: this.volverMenuotravez
    };
    this.volverMenu!.emit(data);
  }
}

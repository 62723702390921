import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-visor-riesgo-aumento-cupo',
  templateUrl: './visor-riesgo-aumento-cupo.component.html',
  styleUrls: ['./visor-riesgo-aumento-cupo.component.css']
})
export class VisorRiesgoAumentoCupoComponent implements OnInit {


  volverMenuotravez = false;
  @Output() volverMenu?: any | undefined = new EventEmitter();

  idError = 0;
  mensajeError = "";

  isLoading = false;

  aumentarCupo: FormGroup;

  nombre = "";
  documento = "";
  idLog :any;

  @Input() informacionCliente: any;
  constructor(
    private dataServices: DataService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
    let nombreCapitalizado = this.capitalizer(this.informacionCliente.data.Nombres)
    this.nombre = nombreCapitalizado
    this.documento = this.informacionCliente.documento;
    this.idLog = this.informacionCliente.idLogAumentoCupo;
    this.aumentarCupo = this.fb.group({
      celular: [this.informacionCliente.data.Celular, [Validators.required]],
      cupoActual: [this.informacionCliente.data.CupoActual, [Validators.required]],
      actividadEconomica: [this.informacionCliente.actividadEconomica, [Validators.required]],
      empresa: [this.informacionCliente.data.Empresa, [Validators.required]],
      puestoTrabajo: [this.informacionCliente.data.PuestoTrabajo, [Validators.required]],
      aniosAntiguedadLaboral: [this.informacionCliente.data.AniosAntiguedadLaboral, [Validators.required]],
      mesesAntiguedadLaboral: [this.informacionCliente.data.MesesAntiguedadLaboral, [Validators.required]],
      ingresos: [this.informacionCliente.data.Ingresos, [Validators.required]],
      cupoSolicitado: ['', [Validators.required]],
      // producto: ['', [Validators.required]],
      // costoProducto: ['', [Validators.required]],
      // plazoCompra: ['', [Validators.required]],
      nombres:[this.nombre]
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  capitalizer(value){
    return value.toLowerCase().replace(/(^|\s)\S/g, match =>{
      return match.toUpperCase();
    })
  }

  enviarAumento() {
    //consume servicio
    this.isLoading = true;

    let data = {
      Documento: this.documento,
      IdLogAumentoCupo: this.idLog,
      FormularioLLeno:this.aumentarCupo.value,


    }


    //si esta ok --> abre modal con info ok --> this.volver()
    this.dataServices.enviarAumentoCupo(data).subscribe((res) => {
      if (res.IdError == 0) {
        this.mensajeError = res.Mensaje;
        this.idError = res.IdError;
        this.isLoading = false;
        this.abrirModal();
        this.volver();
      } else {
        this.idError = res.IdError;
        this.mensajeError = res.Mensaje;
        this.isLoading = false;
        this.abrirModal();

      }
    })
    // sino abre modal con el mensaje de error



  }

  @ViewChild('modalTemplate') modalTemplate?: TemplateRef<any>;

  abrirModal(): void {
    const dialogRef = this.dialog.open(this.modalTemplate, {
      width: '35%',
      height: '35%'
    });

  }

  cerrarModal() {
    this.dialog.ngOnDestroy();
  }


  volver() {
    this.volverMenuotravez = true;
    let data = {
      cerrar: this.volverMenuotravez
    };
    this.volverMenu!.emit(data);
  }

  valorNumerico(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.aumentarCupo.controls['ingresos'].setValue(num);

  }
  valorNumerico2(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.aumentarCupo.controls['cupoSolicitado'].setValue(num);

  }
  valorNumerico3(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.aumentarCupo.controls['costoProducto'].setValue(num);

  }
  valorNumerico4(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.aumentarCupo.controls['plazoCompra'].setValue(num);

  }
  valorNumerico5(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.aumentarCupo.controls['aniosAntiguedadLaboral'].setValue(num);

  }
  valorNumerico6(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 13) {
      var num = num.slice(0, 13);
    }

    this.aumentarCupo.controls['mesesAntiguedadLaboral'].setValue(num);

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-actualizacion-dialog',
  templateUrl: './actualizacion-dialog.component.html',
  styleUrls: ['./actualizacion-dialog.component.css']
})
export class ActualizacionDialogComponent implements OnInit {
  dataInfoActualizar: FormGroup;
  acepta = false;
  constructor(
    public dialogRef: MatDialogRef<ActualizacionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dataService: DataService
  ){  }
  onChangeCheck(check: MatCheckboxChange){
    check.source.color = 'primary';
    if(check.checked){
      this.acepta = true;
    }else{
      this.acepta = false;
    }
  }

  ngOnInit(): void {
    this.dataInfoActualizar = this.formBuilder.group({
      Telefono: ['', [Validators.required, Validators.pattern("[0-9]")]],
      Direccion: ['', [Validators.required]],
    })

    

  }

  cancelar(){
    this.dialogRef.close(false);
  }

  confirmar(){
    this.dialogRef.close(true);
  }

  ActualizarInformacion(){
    if(this.data.dataInfo[0] != null){
      let data = {
        IdConsulta: this.data.dataInfo[0].IdConsulta == null ? "" : this.data.dataInfo[0].IdConsulta,
        Telefono: this.dataInfoActualizar.value.Telefono == null ? "": this.dataInfoActualizar.value.Telefono ,
        Direccion: this.dataInfoActualizar.value.Direccion == null ? "": this.dataInfoActualizar.value.Direccion
      }
      this.dataService.actualizarInfo(data).subscribe(response =>{
        if(response.IdError == 0){
          setTimeout(()=>{
            this.confirmar();
          }, 3000);
              
        }else{
          
        }
      })
      
    }else{
      
    }
  }

}

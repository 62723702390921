import { Injectable, ViewChild } from "@angular/core";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    itemsPerPageLabel = 'Documentos por pagina'; 
    nextPageLabel     = 'Siguiente';
    previousPageLabel = 'Anterior';
    lastPageLabel = 'Ultima pagina';
    firstPageLabel = 'Primera pagina';
  
    getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return endIndex + ' de ' + length;
    };
  }
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-editar-supervisor',
  templateUrl: './editar-supervisor.component.html',
  styleUrls: ['./editar-supervisor.component.scss']
})
export class EditarSupervisorComponent implements OnInit {


  volverListaOtraVez = false;
  @Output() volverLista?: any | undefined = new EventEmitter();
  @Input() infoSupervisor: any;

  tiensasDiunsa = [];

  usuario = '';
  nombre = '';
  telefono = '';
  idTienda = '';
  nombreTienda = '';

  editarTelefono = false;
  editarTienda = false;
  sinEditar = false;

  actualizarSupervisor: FormGroup;

  data;

  constructor(
    private formBuild: FormBuilder,
    private dialog: MatDialog,
    private dataServices: DataService,
    private snackBar: MatSnackBar,

  ) { }

  ngOnInit(): void {
    this.telefono = this.infoSupervisor.Celular
    this.nombreTienda = this.infoSupervisor.NombreTienda
    this.usuario = this.infoSupervisor.UserId
    this.nombre = this.infoSupervisor.Name
    this.actualizarSupervisor = this.formBuild.group({
      idUsuario: [this.infoSupervisor.UserId, []],
      tienda: [this.infoSupervisor.NombreTienda, []],
      //Todo: hacer que solo reciba numeros
      numeroWhatsapp: [this.infoSupervisor.Celular, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
    });

    this.cargarCatalogo();

  }

  volver() {
    this.volverListaOtraVez = true;
    let data = {
      cerrar: this.volverListaOtraVez
    };
    this.volverLista!.emit(data);
  }

  @ViewChild('modalTemplate') modalTemplate?: TemplateRef<any>;

  abrirModal(): void {
    this.editarInformacion()
    const dialogRef = this.dialog.open(this.modalTemplate, {
      width: '581px',
      height: '414px'
    });

  }

  cerrarModal() {
    this.dialog.ngOnDestroy();
    this.volver();
  }
  cerrarModal2() {
    this.dialog.ngOnDestroy();
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  cargarCatalogo() {
    this.dataServices.catalogos().subscribe((res) => {
      if (res.IdError == 0) {
        for (var e in res.Lista) {
          if (res.Lista[e].TipoCatalogo == "Tienda") {
            this.tiensasDiunsa = res.Lista[e].Catalago
          }
        }

        this.tiensasDiunsa.sort((a, b) => a.Id - b.Id)
      } else {
        this.openSnackBar('Catalogo:'+ res.Mensaje, 'cerrar');
      }

    })
  }

  editarInformacion() {
    if (this.nombreTienda != this.actualizarSupervisor.controls['tienda'].value && this.telefono != this.actualizarSupervisor.controls['numeroWhatsapp'].value) {
      this.editarTienda = true;
      this.editarTelefono = true;
      this.sinEditar = false;

      this.idTienda = this.obtenerNombreTienda(this.actualizarSupervisor.controls['tienda'].value);

      this.data = {
        UserId: this.usuario,
        IdTienda: this.idTienda,
        Celular: this.actualizarSupervisor.controls['numeroWhatsapp'].value
      }
    }
    else if (this.telefono != this.actualizarSupervisor.controls['numeroWhatsapp'].value) {

      this.idTienda = this.obtenerNombreTienda(this.actualizarSupervisor.controls['tienda'].value);

      this.sinEditar = false;
      this.editarTienda = false;
      this.editarTelefono = true;
      this.data = {
        UserId: this.usuario,
        IdTienda: this.idTienda,
        Celular: this.actualizarSupervisor.controls['numeroWhatsapp'].value
      }
    } else if (this.nombreTienda != this.actualizarSupervisor.controls['tienda'].value) {

      this.sinEditar = false;
      this.editarTelefono = false;
      this.editarTienda = true;

      this.idTienda = this.obtenerNombreTienda(this.actualizarSupervisor.controls['tienda'].value);

      this.data = {
        UserId: this.usuario,
        IdTienda: this.idTienda,
        Celular: this.telefono
      }
    } else {
      this.editarTienda = false;
      this.editarTelefono = false;
      this.sinEditar = true;
    }
  }

  editarSupervisor() {
    this.dataServices.editarSupervisor(this.data).subscribe(res => {
      if (res.IdError == 0) {
        this.editarTelefono = false;
        this.editarTienda = false;
        this.sinEditar = false;
        this.actualizarSupervisor.reset();
        this.infoSupervisor = null;
        this.cerrarModal();
        this.openSnackBar(res.Mensaje, 'cerrar');
      } else {
        this.openSnackBar(res.Mensaje, 'cerrar');
        this.cerrarModal2();
      }
    })
  }

  obtenerNombreTienda(x): string {
    let tienda;
    this.tiensasDiunsa.forEach(element => {
      if (element.Descripcion == x) {
        tienda = element.Id
      }
    });
    return tienda;
  }

  valorNumerico(event: any) {
    var num = event.target.value;
    var num = num.replace(/[^0-9]/g, "");
    if (num.length > 7) {
      var num = num.slice(0, 8);
      if(num != this.telefono){
        this.editarTelefono = true;
      }else{
        this.editarTelefono = false;
      }
    }

    this.actualizarSupervisor.controls['numeroWhatsapp'].setValue(num);

  }

  valorTienda(){
    if(this.actualizarSupervisor.controls['tienda'].value != this.nombreTienda){
      this.editarTienda = true;
    }else{
      this.editarTienda = false;
    }
  }

  get requiereDocument() {
    return this.actualizarSupervisor.get('numeroWhatsapp')?.hasError('required')
  }

  get lenghtDocument() {
    return this.actualizarSupervisor.get('numeroWhatsapp')?.hasError('minlength')
  }

}

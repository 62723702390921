import { Component, OnInit, ViewChild, Injectable, Inject, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { EventEmitter } from 'protractor';
import { environment } from 'src/environments/environment.prod';
import * as countdown from 'src/assets/scripts/countdown.js';
export interface DialogData {
  user: any;
}
export interface Tiempo {
  hours: number;
  minutes: number;
  seconds: number;
}
@Component({
  selector: 'app-aumentocupo',
  templateUrl: './aumentocupo.component.html',
  styleUrls: ['./aumentocupo.component.css']
})
export class AumentocupoComponent implements OnInit, OnDestroy {



  dataIntra: FormGroup = this.formBuilder.group({
    Documento: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(14)]],
  });

  dataIntra2: FormGroup = this.formBuilder.group({
    OTP: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });

  dataIntra3: FormGroup = this.formBuilder.group({
    Identificacion: ['', [Validators.required]],
    Nombre: ['', [Validators.required]],
    CupoActual: ['', [Validators.required]],
    Celular: ['', [Validators.required]],
    AntiguedadLaboralAnios: ['', [Validators.required]],
    AntiguedadLaboralMeses: ['', [Validators.required]], 
    ActividadEconomica: ['', [Validators.required]],
    Empresa: ['', [Validators.required, Validators.minLength(3)]],
    Puesto: ['', [Validators.required,Validators.minLength(3)]],
    Ingresos: ['', [Validators.required,Validators.minLength(2),Validators.pattern("^[0-9]+")]],
    CupoSolicitado: ['', [Validators.required,Validators.pattern("^[0-9]+")]],
    Producto: ['', [Validators.required,Validators.minLength(3)]],
    CostoProducto: ['', [Validators.required,Validators.pattern("^[0-9]+")]],
    Plazo: ['', [Validators.required]],
    // Prima: ['', [Validators.required,Validators.minLength(1),Validators.pattern("^[0-9]+")]],
    // Vendedor: ['', [Validators.required,Validators.minLength(3)]],
  });

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  constructor(private _snackBar: MatSnackBar, private formBuilder: FormBuilder, private dataService: DataService, public dialogo: MatDialog, public dialog: MatDialog) { }
  waitSend = false
  dataElement
  time:number = environment.tiempoOtp;
  id;

  fechaFinal: number =0;
  tiempo!:Tiempo ;
  timerId? : any = null;

  priForm:boolean = true;
  segForm:boolean = false;
  terForm:boolean = false;
  cuaForm:boolean = false;
  ActividadEconomica = [];
  Plazos = [];
  imageSrc: string = 'assets/img/default-user.png';

  ngOnInit() {
    
    
  }

  iniciarContador(){
    
    this.timerId = countdown(this.fechaFinal,(ts)=>{
     let hoy = new Date();
       if(hoy.getTime() <= this.fechaFinal){
       this.tiempo.hours = ts.hours == undefined || ts.hours <=0 ? 0: ts.hours;
       this.tiempo.minutes = ts.minutes == undefined || ts.minutes <=0 ? 0: ts.minutes;
       this.tiempo.seconds = ts.seconds == undefined || ts.seconds <=0 ? 0: ts.seconds;
       }else{
        this.time = 0;
        clearInterval(this.timerId);
       }
   }, countdown.HOURS | countdown.MINUTES | countdown.SECONDS);

   }

   ngOnDestroy() {
     if(this.timerId){
       clearInterval(this.timerId);
     }
   }

  onKeyNumDataSocio(event: any, campo) {
    var format = /[ `!¡@#$%^&*()'¿_+\-=\[\]{};:"\\|,.<>\/?~AaBbCcDdEeFfGgHhIiJjKkLlMmNnÑñOoPpQqRrSsTtUuVvWwXxYzZz ]/;
    var num = event.target.value;
    if (format.test(num)) {
      var id = num.indexOf(event.key)
      var num = num.slice(0, (id));
      this.dataIntra.controls[campo].setValue(num);
    }
  }

  onKeyNumOtp(event: any, campo) {
    var format = /[ `!¡@#$%^&*()'¿_+\-=\[\]{};:"\\|,.<>\/?~AaBbCcDdEeFfGgHhIiJjKkLlMmNnÑñOoPpQqRrSsTtUuVvWwXxYzZz ]/;
    var num = event.target.value;
    if (format.test(num)) {
      var id = num.indexOf(event.key)
      var num = num.slice(0, (id));
      this.dataIntra2.controls[campo].setValue(num);
    }
  }

  reSendOtP(){
    this.dataIntra2.get('OTP').setValue('');
    this.continuar('Continuar');
    this.time = environment.tiempoOtp;
    this.tiempo = {
      hours: 0,
      minutes:0,
      seconds:0,
    }
      let fechaFin = new Date();
      this.fechaFinal = fechaFin.getTime() + (this.time * 60000);
      this.iniciarContador();
  }

  validarOtp(){

     let datos = {
      IdCliente:this.dataElement.IdCliente,
      Documento:this.dataElement.Documento,
      IdLogAumentosCupo: this.dataElement.IdLogAumentosCupo,
      TipoClienteAbordaje: this.dataElement.TipoClienteAbordaje,
      OTP:this.dataIntra2.get('OTP').value
     };
      this.waitSend = true;
      this.time=0;
      this.dataService.aumentoCupoValidarOTP(datos).subscribe(data=>{
      if(data.IdError ==0){

        this.priForm = false;
        this.segForm = false;
        this.terForm = false;
        this.cuaForm = true;
        this.dataElement = data;
        this.listadosCatalogo();
        this.precargarData( data );
      }else{
       this.waitSend = false;
       let cal = {
        IdError : data.IdError,
        Mensaje : data.Mensaje
       }
       if(data.IdError !=4 && data.IdError !=5){
        this.priForm = true;
        this.segForm = false;
        this.terForm = false;
        this.setearValores();
       }

       this.irPopUpInfo(cal);
      }
     });
    }

  irPopUpInfo(data1) {
    const dialogRef = this.dialog.open(popUpInfos, {
      width: '450px',
      height: '',
      data: { user: data1}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
      }
    });
  }

  listadosCatalogo(){
    this.dataService.catalogos().subscribe(data => {
      if (data.IdError == 0) {
        this.waitSend = false;
        for (let e in data.Lista) {
          if (data.Lista[e].TipoCatalogo == 'ActividadEconomica') {
            this.ActividadEconomica = data.Lista[e].Catalago;
          }
          if (data.Lista[e].TipoCatalogo == 'PlazosAumentoCupo') {
            this.Plazos = data.Lista[e].Catalago;
          }
        }
      }
    });
  }
  consultarCedula() {
    this.dataElement = null;
    var dts = {
      Documento: this.dataIntra.value.Documento
     }
    this.waitSend = true;
    this.dataService.aumentoCupoConsulta(dts).subscribe(response => {
      this.waitSend = false;
       if (response.IdError == 0) {
        this.priForm = false;
        this.segForm = true;
         this.dataElement = response;
       } else {
        this.irPopUpInfo(response)
       }
    });
  }
  validarAumento(){
    let Mensaje = '';
    let IdError = 0;

    if(this.dataIntra3.value.AntiguedadLaboralAnios==0 && this.dataIntra3.value.AntiguedadLaboralMeses==0){
      IdError = 1;
      Mensaje = "Los campos antiguedad laboral (años o meses) deben estar diligenciados y al menos unos de ellos debe ser mayor a 0. "
    }
    if(this.dataIntra3.value.CupoSolicitado <= this.dataElement.CupoActual){
      IdError = 1;
      Mensaje += "El cupo solicitado debe ser mayor al cupo actual."
    }
    if(IdError!=0){
      let cal = {
        IdError : 1,
        Mensaje : Mensaje
       }
       this.irPopUpInfo(cal)
    
    }else{
    var dts = {
      Identificacion: this.dataElement.Documento,
      IdCliente:this.dataElement.IdCliente,
      PrimerNombre:this.dataElement.PrimerNombre,
      SegundoNombre:this.dataElement.SegundoNombre,
      PrimerApellido:this.dataElement.PrimerApellido,
      SegundoApellido:this.dataElement.SegundoApellido,
      Celular:this.dataElement.Celular,
      ActividadEconomica :this.dataIntra3.value.ActividadEconomica,
      Empresa:this.dataIntra3.value.Empresa,
      Puesto:this.dataIntra3.value.Puesto,
      AntiguedadLaboralAnios:this.dataIntra3.value.AntiguedadLaboralAnios,
      AntiguedadLaboralMeses:this.dataIntra3.value.AntiguedadLaboralMeses,
      Ingresos: this.dataIntra3.value.Ingresos,
      CupoActual:this.dataElement.CupoActual,
      CupoSolicitado: this.dataIntra3.value.CupoSolicitado,
      Producto:this.dataIntra3.value.Producto,
      CostoProducto: this.dataIntra3.value.CostoProducto,
      Plazo: this.dataIntra3.value.Plazo,
      // Prima:this.dataIntra3.value.Prima,
      // Vendedor:this.dataIntra3.value.Vendedor
      IdLogAumentosCupo: this.dataElement.IdLogAumentosCupo,
      TipoClienteAbordaje: this.dataElement.TipoClienteAbordaje
     }

    this.waitSend = true;
    this.dataService.aumentoCupoValidacionFinal(dts).subscribe(response => {
      this.waitSend = false;
        this.priForm = true;
        this.cuaForm = false;
        this.dataElement = null;
        this.setearValores();
        this.irPopUpInfo(response)
    });
  }
  }
  continuar(result:string){
    let data = {
      Documento:this.dataElement.Documento,
      IdCliente:this.dataElement.IdCliente,
      IdLogAumentosCupo: this.dataElement.IdLogAumentosCupo,
      TipoClienteAbordaje: this.dataElement.TipoClienteAbordaje,
      Resultado:result
    }
    if(result =="Continuar"){
      this.waitSend = true;
      this.dataService.aumentoCupoEnvioOTP(data).subscribe(response => {
        this.waitSend = false;
         if (response.IdError == 0) {
          this.priForm = false;
          this.segForm = false;
          this.terForm = true;
          this.time = environment.tiempoOtp;
          this.tiempo = {
            hours: 0,
            minutes:0,
            seconds:0,
          }
            let fechaFin = new Date();
            this.fechaFinal = fechaFin.getTime() + (this.time * 60000);
              this.iniciarContador();
         } else {
          this.priForm = true;
          this.segForm = false;
          this.terForm = false;
          this.setearValores();
          this.irPopUpInfo(response)
         }
    });
    }else{
      this.priForm = true;
      this.segForm = false;
      this.setearValores();
      this.dataService.aumentoCupoEnvioOTP(data).subscribe(response => {});
  }
  }

  validarMontoMinimoIngreso(event){
    let monto = event.target.value;
    if (monto <= 0) {
      this.dataIntra3.controls['Ingresos'].setErrors({'incorrect': true});
    }else{
      this.dataIntra3.controls['Ingresos'].setErrors(null);
    }
  }

  // validarMontoMinimoPrima(event){
  //   let monto = event.target.value;
  //   if (monto <= 0) {
  //     this.dataIntra3.controls['Prima'].setErrors({'incorrect': true});
  //   }else{
  //     this.dataIntra3.controls['Prima'].setErrors(null);
  //   }
  // }

  validarMontoMinimoCosto(event){
    let monto = event.target.value;
    if (monto <= 0) {
      this.dataIntra3.controls['CostoProducto'].setErrors({'incorrect': true});
    }else{
      this.dataIntra3.controls['CostoProducto'].setErrors(null);
    }
  }

  validarMontoMinimo(event){
    let monto = Number((event.target.value.replace(',','')).replace('.',''));
    if (monto <= 0 || monto < this.dataElement.CupoActual ) {
      this.dataIntra3.controls['CupoSolicitado'].setErrors({'incorrect': true});
    }else{
      this.dataIntra3.controls['CupoSolicitado'].setErrors(null);
    }
  }


  validateNumber(event: any, campo) {
    var format = /[ `!¡@#$%^&*()'¿_+\-=\[\]{};:"\\|,.<>\/?~AaBbCcDdEeFfGgHhIiJjKkLlMmNnÑñOoPpQqRrSsTtUuVvWwXxYyZz ]/;
    var num = event.target.value;
    if (format.test(num)) {
      var id = num.indexOf(event.key)
      var num = num.slice(0, (id));
      this.dataIntra3.controls[campo].setValue(num);
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  setearValores(){
    this.time = environment.tiempoOtp;
    this.dataIntra.get('Documento').setValue('');
    this.dataIntra.controls.Documento.markAsUntouched();
    this.dataIntra2.get('OTP').setValue('');
    this.dataIntra2.controls.OTP.markAsUntouched();
    this.dataIntra3.reset();
  }
  
  precargarData(data:any){

    this.dataIntra3.get('Identificacion').setValue(data.Documento);
    this.dataIntra3.controls["Identificacion"].disable();
    let Nombre = data.PrimerNombre +" "+(data.SegundoNombre?data.SegundoNombre:"")+" "+data.PrimerApellido+" "+(data.SegundoApellido?data.SegundoApellido:"");
    this.dataIntra3.get('Nombre').setValue(Nombre);
    this.dataIntra3.controls["Nombre"].disable();
    this.dataIntra3.get('Celular').setValue(data.Celular);
    this.dataIntra3.controls["Celular"].disable();
    this.dataIntra3.get('CupoActual').setValue(data.CupoActual);
    this.dataIntra3.controls["CupoActual"].disable();
    this.dataIntra3.get('AntiguedadLaboralAnios').setValue(data.AntiguedadLaboralAnios);
    this.dataIntra3.get('AntiguedadLaboralMeses').setValue(data.AntiguedadLaboralMeses);
    this.dataIntra3.get('ActividadEconomica').setValue(data.ActividadEconomica);
    this.dataIntra3.get('Empresa').setValue(data.Empresa);
    this.dataIntra3.get('Puesto').setValue(data.Puesto);
    this.dataIntra3.get('Ingresos').setValue(data.Ingresos);
  }

  onKeyAniosPre(event: any) {


    let num = parseInt(event.target.value);
    if (num < 0) {
      num = 0;
    }
    this.dataIntra3.controls['AniosAntiguedadLaboral'].setValue(num);

  }

  onKeyMesesPre(event: any) {


    let num = parseInt(event.target.value);
    if (num < 0) {
      num = 0;
    }
    this.dataIntra3.controls['MesesAntiguedadLaboral'].setValue(num);

  }
}

@Component({
  selector: 'popUpInfos',
  templateUrl: 'popUpInfos.html',
  styleUrls: ['./aumentocupo.component.css']
})
export class popUpInfos implements OnInit {
  waitSend
  imageSrc: string = 'assets/img/default-user.png';

  constructor(
    public dialogRef: MatDialogRef<popUpInfos>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private dataService: DataService, public dialogo: MatDialog) {
  }

  ngOnInit() {

  }
  onNoClick2(): void {
    this.dialogRef.close(undefined);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

}